import { useState } from 'react';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/forms/AuthForm.css';
import PreNav from 'components/nav/PreNav';
import Cookies from 'js-cookie';

function ResetInit(props) {
    if (Cookies.get('token')) {
        window.location.href = '/leagues'
    }
    const [email,setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const[error,setError] = useState('');
    const[valid,setValid] = useState(true);
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (valid === true & email !== '') {
            let data = new FormData();
            data.append('email', email);
            fetch('/api/auth/requestReset', {
                method: 'POST',
                body: data
            }).then(async response => {
                const data = await response.json();
    
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    setError(error);
                } else {
                    setError('');
                    window.location.href = '/resetPassword';
                }
    
            }).catch(error => {
                console.error('There was an error!', error);
            });
          }
    }
    const validateEmail = e => {
        const value = e.target.value.trim().toLowerCase();
        if(!regEmail.test(value)){
            setEmailError('Please enter a valid email.');
            setValid(false);
            document.getElementById('email').style.borderColor = '#D82334';
        }
        else {
            setEmailError('');
            setValid(true);
            document.getElementById('email').style.borderColor = '#E0E0E0';
        }
    }
    return (
        <>
            <PreNav />
            <Row style={{paddingLeft:'100px',paddingRight:'100px'}}>
                <Col xs={12} sm={{span: 4,offset:4}} >
                    <h1>Reset Password</h1>
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit}>
                            <div className="input-wrapper">
                                <input className="form-input" placeholder="Email" id="email" type="email" onBlur={validateEmail} value={email} onChange={(e) => setEmail(e.target.value.trim().toLocaleLowerCase())} />
                                <span className="error" id="error">{emailError}</span>
                            </div>
                            <div className="form-error">{error}</div>
                            <input type="submit" className="form-btn reset-email-submit" value="Send Reset Email" />
                        </form>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ResetInit;