import BuyPrism from 'pages/prism/BuyPrism';
import PrismDashboard from 'pages/prism/PrismDashboard';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';

function Prism(props) {
    const [PrismStatus, setPrismStatus] = useState(false);
    useEffect(() => {
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch(`/api/users/me`, requestOptions).then(async response => {
            const user = await response.json();
            if (user.services.includes('prism')) {
                setPrismStatus(true);
            } else {
                setPrismStatus(false);
            }
        });
    }, []);
    if (PrismStatus === false) {
        return <BuyPrism />
    } else {
        return <PrismDashboard />
    }
    
}

export default Prism;