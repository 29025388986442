import PreNav from 'components/nav/PreNav';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import Cookies from 'js-cookie';
import LoginForm from 'components/forms/LoginForm';
import {useEffect} from 'react';
import {PageView, initGA, Event} from 'helpers/Tracking';

function Login(props) {
    useEffect(() => {
        initGA('UA-161394853-1');
        PageView();
    }, []);
    if (Cookies.get('token')) {
        window.location.href = '/leagues'
    }
    return (
        <>
            <PreNav />
            <Row style={{paddingLeft:'100px',paddingRight:'100px'}}>
                <Col xs={12} sm={{span: 4,offset:4}} >
                    <h1>Login</h1>
                    <LoginForm from={props.from} search={props.search}/>
                    <center>
                        <p className="help-wrapper">Don't have an account yet? Get one <a href="/signup" className="help" onClick={() => Event("HELP","Don't have account","LOGIN_PAGE")}>here</a>.</p>
                        <p className="help-wrapper">Forgot your password? Reset it <a href="/forgotPassword" className="help" onClick={() => Event("HELP","Forgot password","LOGIN_PAGE")}>here</a>.</p>
                    </center>
                </Col>
            </Row>
        </>
    );
}

export default Login;