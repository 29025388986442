import { useState, useEffect } from 'react';
import Nav from 'components/nav/Nav';
import 'stylesheets/pages/CreateLeague.css';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/forms/AuthForm.css';
import type_0 from 'static/images/type_0.svg';
import type_1 from 'static/images/type_1.svg';
import Cookies from 'js-cookie';

function CreateLeague(props) {
    const [leagueName,setLeagueName] = useState('');
    const [pllType,setPllType] = useState('0');
    const [teamName,setTeamName] = useState('');
    const [formStep,setFormStep] = useState(1);
    const validateTextField = (e) => {
        if (e.target.value === '') {
            e.target.style.borderColor = '#D82334';
        } else {
            e.target.style.borderColor = '#E0E0E0';
        }
	}
    function next() {
        if (formStep === 1) {
			if (leagueName !== '' & document.getElementById('league_select').value === "pll") {
				setFormStep(2);	
			} else if (leagueName !== '' & document.getElementById('league_select').value === "nll") {
				setFormStep(3);
			} else {
				if (leagueName === '') {
					document.getElementById('league_name').style.borderColor = '#D82334';
				}
				if (document.getElementById('league_select').value === "") {
					document.getElementById('league_select').style.borderColor ='#D82334';
				}
			}
		} else if (formStep === 2) {
            setFormStep(3);
        }
	}
	function back() {
		if (formStep > 1) {
			setFormStep(formStep-1)
		}
    }
    useEffect(() => {
        if (pllType === '0') {
            document.getElementById('type-0').style.borderColor = '#000';
            document.getElementById('type-1').style.borderColor = '#99ccff';

        } else {
            document.getElementById('type-1').style.borderColor = '#000';
            document.getElementById('type-0').style.borderColor = '#99ccff';
        }
    }, [pllType])
    useEffect(() => {
        if (formStep === 1) {
            document.getElementById('1').style.display = 'block';
            document.getElementById('back').style.display = 'none';
            document.getElementById('3').style.display = 'none';
            document.getElementById('2').style.display = 'none';
            document.getElementById('next').style.display = 'block';
            document.getElementById('finish').style.display = 'none';
        } else if (formStep === 2) {
            document.getElementById('1').style.display = 'none';
            document.getElementById('2').style.display = 'block';
            document.getElementById('3').style.display = 'none';
            document.getElementById('back').style.display = 'block';
            document.getElementById('next').style.display = 'block';
            document.getElementById('finish').style.display = 'none';
        } else if (formStep === 3) {
            document.getElementById('1').style.display = 'none';
            document.getElementById('2').style.display = 'none';
            document.getElementById('3').style.display = 'block';
            document.getElementById('back').style.display = 'block';
            document.getElementById('next').style.display = 'none';
            document.getElementById('finish').style.display = 'block';
        }
    }, [formStep])
    const handleSubmit = (e) => {
        e.preventDefault()
		let data = new FormData();
		data.append('league_name', leagueName);
		data.append('league_type', document.getElementById('league_select').value);
		data.append('pll_type', pllType);
        data.append('team_name', teamName);
        if (teamName.trim() !== '') {
            const requestOptions = {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
                body: data
              };
             fetch('/api/createLeague', requestOptions)
             .then(async response => {
                 const data = await response.json();
                
                 // check for error response
                 if (!response.ok) {
                     // get error message from body or default to response statusText
                     window.location.href = '/leagues';
                 } else {
                    window.location.href = '/league/'+data['leagueId']+'?new=true';
                 }
     
             })
             .catch(error => {
                 console.error('There was an error!', error);
             });
        } else {
            document.getElementById('league_name').style.borderColor = '#D82334';
        }
        
    }
    return (
        <>
            <Nav />
            <Row>
                <Col xs={{span: 8,offset:2}} sm={{span: 4,offset:4}} >
                <div id="1">
                    <center>
                        <h1>Create League</h1>
                        <div className="input-wrapper">
                            <input className="form-input" placeholder="League Name" type="text" id="league_name" value={leagueName} onBlur={validateTextField} onChange={(e) => setLeagueName(e.target.value)}/>
                        </div>
                        <div className="input-wrapper">
                            <select className="form-input" id="league_select" style={{borderWidth:'2px',outline:'none'}} required>
                                <option disabled selected value> -- select an league -- </option>
                                <option value="pll">PLL</option>
                                <option value="nll" disabled>NLL</option>
                            </select>
                        </div>
                    </center>
                    </div>
                </Col>
                <Col span={12}>
                    <div id="2" style={{display:'none'}}>
                    <center>
						<h1 style={{marginBottom:'0px'}}>Create A League</h1>
						<h3 style={{fontWeight:'400',marginTop:'0px',marginBottom:'20px'}}>League Details</h3>
                        <div className="pll-type-select">
                            <button className="pll-type-select-btn" id="type-0" style={{borderColor:'99ccff',marginBottom:'10px'}} onClick={() => setPllType('0')}>
                                <img src={type_0} className="type-image" />
                                <p>+ 5 Bench Players</p>
                            </button>
                            <button className="pll-type-select-btn" id="type-1" style={{borderColor:'99ccff'}} onClick={() => setPllType('1')}>
                                <img style={{padding:'none'}} src={type_1} className="type-image" />
                                <p style={{padding:'none'}}>+ 5 Bench Players</p>
                            </button>
                        </div>
                        <button style={{backgroundColor:'#000',color:'#fff',height:'35px',fontWeight:'500',border:'none',borderRadius:'10px',fontSize:'16px',marginBottom:'15px',paddingLeft:'15px',paddingRight:'15px'}} onClick={() => window.open('https://www.notion.so/Lineups-f04f94ebcdda435f985f0bd46afb4167','_blank')}>Learn More Here</button>
                    </center>
                    </div>
                </Col>
                <Col xs={{span: 8,offset:2}} sm={{span: 4,offset:4}} >

                    <div id="3" style={{display:'none'}}>
                    <center>
						<h1 style={{marginBottom:'0px'}}>Create A League</h1>
						<h3 style={{fontWeight:'400',marginTop:'0px',marginBottom:'20px'}}>Your Team</h3>
                        <div className="input-wrapper">
                            <input className="form-input" placeholder="Team Name" type="text" id="league_name" value={teamName} onBlur={validateTextField} onChange={(e) => setTeamName(e.target.value)}/>
                        </div>
                    </center>
                    </div>
                    <div className="form-controls">
                        <button className="form-control finish" id="finish" onClick={handleSubmit}>Finish</button>
                        <button className="form-control" id="next" onClick={next}>Next</button>
                        <button className="form-control" style={{display:'none'}} id="back" onClick={back}>Back</button>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default CreateLeague;