import 'stylesheets/pages/Home.css';
import logo from 'static/images/logo.png';
import { Link } from 'react-router-dom';
import email from 'static/icons/email.svg';
import instagram from 'static/icons/instagram.svg';
import twitter from 'static/icons/twitter.svg';
import {PageView, initGA, Event} from 'helpers/Tracking';
import {useEffect} from 'react';

function HomeNav(props) {
    useEffect(() => {
        initGA('UA-161394853-1');
    }, []);
    return (
        <div className={`home-nav ${props.opaque ? 'opaque' : ''}`}>
            <div className="home-nav-brand">
                <img className="home-nav-logo" src={logo} />
                <h1 className="home-nav-brand-name">Flow Fantasy</h1>
            </div>
            {/* <div className={`nav-socials ${props.opaque ? 'show' : ''}`}>
                    <a className="home-nav-link nav-instagram" href="https://www.instagram.com/flowfantasyinc/" onClick={() => Event("NAV",'NAV Instagram',"HOME_PAGE")}><img src={instagram} /></a>
                    <a className="home-nav-link nav-twitter" href="https://twitter.com/FlowFantasyInc" onClick={() => Event("NAV",'NAV Twitter',"HOME_PAGE")}><img src={twitter} /></a>
                    <a className="home-nav-link nav-email" href="mailto:support@flowfantasy.com" onClick={() => Event("NAV",'NAV Email',"HOME_PAGE")}><img src={email} /></a>
            </div> */}
            <div className="home-nav-links">
                
                <Link to="/login">
                    <a className="home-nav-link" onClick={() => Event("NAV",'NAV Login',"HOME_PAGE")}>Login</a>
                </Link>
                <Link to="/signup">
                    <button className="home-nav-link sign-up" onClick={() => Event("NAV",'NAV Sign Up',"HOME_PAGE")}>Sign Up</button>
                </Link>
            </div>
        </div>
    );
}

export default HomeNav;