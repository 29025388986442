import Nav from 'components/nav/Nav';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import LoadingError from 'helpers/LoadingError';
import 'stylesheets/forms/AuthForm.css';


function Join({match, locations}) {
    const { params: { id } } = match;
    const [canJoin, setCanJoin] = useState(true);
    const [error,setError] = useState(false);
    const [leagueName,setLeagueName] = useState('');
    const [teamName, setTeamName] = useState('');
    useEffect(() => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
		  };
		 fetch(`/api/join/${id}/state`, requestOptions).then(async response => {
			 const data = await response.json();
			 // check for error response
			 if (response.status === 400) {
                 // get error message from body or default to response statusText
                 Cookies.remove('token');
				 window.location.href = '/login';
			 } else if (response.status === 202) {
                setCanJoin(false);
                setLeagueName(data['name'])
             } else if (response.status === 201) {
                window.location.href = `/league/${id}`
             } else {
                setCanJoin(true);
                setLeagueName(data['name'])
             }
 
		 }).catch(() => {
            setError(true);
         })
    }, []);
    const validateTextField = (e) => {
        if (e.target.value === '') {
            e.target.style.borderColor = '#D82334';
        } else {
            e.target.style.borderColor = '#E0E0E0';
        }
    }
    const handleSubmit = (e) => {
        if (teamName !== '') {
            e.preventDefault();
            let data = new FormData();
            data.append('team_name', teamName);
            const requestOptions = {
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
                method: 'POST',
                body: data
		    };
            fetch(`/api/join/${id}`, requestOptions).then(async response => {
                const resp_data = await response.json();
                if (!response.ok) {
                    setError(true);
                } else {
                    window.location.href = `/league/${id}`
                }
            });
        }
    }
    if (canJoin === true) {
        return (
            <>
                <Nav />
                <div className="main">
                    <Row>
                        <Col xs={12} sm={{span: 4,offset:4}} >
                            <center>
                            <h1>Join {leagueName}</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="input-wrapper">
                                    <input className="form-input" placeholder="Team Name" type="text" id="team_name" value={teamName} onBlur={validateTextField} onChange={(e) => setTeamName(e.target.value)}/>
                                </div>
                                <input type="submit" className="form-btn login-submit" value="Join" />
                            </form>
                            </center>
                        </Col>
                    </Row>
                </div>
                {error ? <LoadingError error="A Network or Server Error has occured." /> : null}
            </>
        ); 
    } else {
        return (
            <>
                <Nav />
                <div className="main">
                    <Row>
                        <h3>You can't join {leagueName} at this time.</h3>
                    </Row>
                </div>
            </>
        );
    }
}

export default Join;