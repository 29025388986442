import { useEffect } from 'react';
import 'stylesheets/DailyNav.css';
import {PageView, initGA, Event} from 'helpers/Tracking';

function DailyNav(props) {
    
    useEffect(() => {
        if (props.active) {
            document.getElementById(props.active).classList.add('active');
        }
    }, [])
    return (
        <div class="wrapper">
            <nav class="tabs">
                <a id="lobby" className="daily-nav-link" href="/daily" onClick={() => Event("NAV",'Daily Lobby',"DAILY")}>Lobby</a>
                <a id="lineups" className="daily-nav-link" href="/daily/lineups" onClick={() => Event("NAV",'Daily Lineups',"DAILY")}>Lineups</a>
                <a id="contests" className="daily-nav-link" href="/daily/contests" onClick={() => Event("NAV",'Daily Contests',"DAILY")}>Contests</a>
            </nav>
        </div>
    );
}

export default DailyNav;