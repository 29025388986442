import {useState, useEffect} from 'react';
import Cookies, { set } from 'js-cookie';
import 'stylesheets/JoinCode.css';
import {Event} from 'helpers/Tracking';

function JoinCode(props) {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const find = () => {
        setError('');
        let data = new FormData();
        data.append('code', code);
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
            method: 'POST',
            body: data
        };
        fetch(`/api/daily/contests/private`, requestOptions).then(async response => {
            const data = await response.json();
            if (response.ok) {
                var contest = data.id;
                props.setJoining(contest);
                props.setCodeOpen(false);
                props.setJoinOpen(true);
            } else {
                setError(data.error)
            }
		}).catch(() => {
            console.log('Error!')
        });
    }
    return (
        <div>
            <h2 style={{marginTop:'-5px'}}>Join With Code</h2>
            <center><input placeholder="6 Digit Code" className="code-input" pattern="^[\p{L}\p{N}]{6,}$" minlength="6" maxlength="6" value={code} onChange={(e) => setCode(e.target.value)} /></center>
            <center><span className="join-error">{error}</span></center>
            <center><button className="join-contest-btn" style={{marginTop:'15px'}} onClick={() => {find();Event('DAILY','Join contest by code','DAILY')}}>Join Contest</button></center>
        </div>
    );
}

export default JoinCode;