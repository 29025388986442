import { useState, useEffect } from 'react';
import 'stylesheets/forms/AuthForm.css';
import Cookies from 'js-cookie';
import {PageView, initGA, Event} from 'helpers/Tracking';

function LoginForm(props) {
    const [error, setError] = useState('');
    const [emailError,setEmailError] = useState('');
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [valid,setValid] = useState(true);
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validateEmail = e => {
        const value = e.target.value.trim().toLowerCase();
        if(!regEmail.test(value)){
            setEmailError('Please enter a valid email.');
            setValid(false);
            document.getElementById('email').style.borderColor = '#D82334';
        }
        else {
            setEmailError('');
            setValid(true);
            document.getElementById('email').style.borderColor = '#E0E0E0';
        }
    }
    const validatePassword = e => {
        const value = e.target.value.trim();
        if(value === ''){
            setValid(false);
            document.getElementById('password').style.borderColor = '#D82334';
        }
        else {
            setValid(true);
            document.getElementById('password').style.borderColor = '#E0E0E0';
        }
    }
    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');

        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    }
    const handleSubmit = e => {
        e.preventDefault();
        if (valid === true & email !== '' & password !== '') {
            let data = new FormData();
            data.append('email', email);
            data.append('password', password);
            fetch('/api/auth/login', {
                method: 'POST',
                body: data
            }).then(async response => {
                const resp_data = await response.json();
                if (!response.ok) {
                    setError(resp_data['message']);
                } else {
                    Cookies.set('token',resp_data['token']);
                    var query = getQueryParams(document.location.search);
                    if (query['redirect']) {
                        window.location.href = query['redirect'];
                    } else {
                        window.location.href = '/leagues';
                    }
                }
            });
        }
    }
    return (
        <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input className="form-input" placeholder="Email" id="email" type="email" onBlur={validateEmail} value={email} onChange={(e) => setEmail(e.target.value.trim().toLocaleLowerCase())} />
                    <span className="input-error" id="error">{emailError}</span>
                </div>
                <input className="form-input" placeholder="Password" id="password" type="password" onBlur={validatePassword} value={password} onChange={(e) => setPassword(e.target.value.trim())} />
                <div className="form-error">{error} </div>
                <center><input type="submit" className="form-btn login-submit" value="Login" onClick={() => Event("LOGIN","Login","LOGIN_PAGE")}/></center>
            </form>
        </div>
    );
}

export default LoginForm;