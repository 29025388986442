import {useState,useEffect} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {useQuery} from 'react-query';
import {getTrades} from 'api/LeagueAPI';
import LeagueLoadingSpinner from 'components/LeagueLoadingSpinner';
import 'stylesheets/Trades.css';
import { Row, Col } from 'react-simple-flex-grid';
import Cookies from 'js-cookie';

const PlayerTradeCard = props => {
    return (
        <a href={`/player/${props.player.officialId}`} style={{color:'#000'}} target="_blank">
            <div style={{display:'flex',alignItems:'center'}} >
                <img src={props.player.profileUrl} style={{height:'40px',borderRadius:'20px',marginRight:'7px'}}/>
                <div>
                    <h2 style={{fontSize:'17px',margin:'0px'}}>{props.player.firstName} {props.player.lastName}</h2>
                    <h2 style={{fontSize:'17px',fontWeight:'400',margin:'0px'}}>{props.player.positionLongName}</h2>
                </div>
            </div>
        </a>
    );
}

const TradeCard = props => {
    const [loading,setLoading] = useState(false);
    const trade_action = (action, trade_id) => {
        setLoading(true);
		const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
		  };
		 fetch(`/api/trades/${trade_id}/${action}`, requestOptions).then(async response => {
			const data = await response.json();
            console.log(data)
		 });
         
    }
    const trade = props.trade;
    return (
        <div className="trade-card">
            <div style={{display:'flex',justifyContent:'space-evenly',width:'100%'}}>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <h1 style={{marginTop:'10px',fontSize:'30px',marginBottom:'5px'}}>YOU</h1>
                    {trade.request.map((player) => (
                        <PlayerTradeCard player={player}/>
                    ))}
                </div>
                <div style={{height:'180px',width:'2px',backgroundColor:'#000',justifySelf:'center'}} />
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <h1 style={{marginTop:'10px',fontSize:'30px',marginBottom:'5px'}}>FOR</h1>
                    {trade.inReturn.map((player) => (
                        <PlayerTradeCard player={player}/>
                    ))}
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',width:'100%'}}>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',borderTop:'1px solid #000',borderBottom:'1px solid #000',padding:'5px',fontWeight:'500'}}>{trade.proposedTo.first_name[0]}. {trade.proposedTo.last_name}</div>
                <div style={{height:'30px',width:'2px',backgroundColor:'#000',justifySelf:'center'}} />
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',borderTop:'1px solid #000',borderBottom:'1px solid #000',padding:'5px',fontWeight:'500'}}>{trade.proposer.first_name[0]}. {trade.proposer.last_name}</div>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',width:'100%',alignItems:'center',marginTop:'10px'}}>
                <button className={`accept-trade ${loading ? 'trade-action-loading' : ''}`} onClick={() => trade_action('accept',trade._id)}>Accept</button>
                <div style={{height:'20px',width:'2px',backgroundColor:'#fff',justifySelf:'center'}} />
                <button className={`decline-trade ${loading ? 'trade-action-loading' : ''}`} onClick={() => trade_action('decline',trade._id)}>Decline</button>
            </div>
        </div>
    );
}

const TradeProposedCard = props => {
    const trade = props.trade;
    return (
        <div className="trade-card-proposed">
            <div style={{display:'flex',justifyContent:'space-evenly',width:'100%'}}>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <h1 style={{marginTop:'10px',fontSize:'30px',marginBottom:'5px'}}>YOU</h1>
                    {trade.inReturn.map((player) => (
                        <PlayerTradeCard player={player}/>
                    ))}
                </div>
                <div style={{height:'180px',width:'2px',backgroundColor:'#000',justifySelf:'center'}} />
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <h1 style={{marginTop:'10px',fontSize:'30px',marginBottom:'5px'}}>FOR</h1>
                    {trade.request.map((player) => (
                        <PlayerTradeCard player={player}/>
                    ))}
                </div>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',width:'100%'}}>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',borderTop:'1px solid #000',padding:'5px',fontWeight:'500'}}>{trade.proposedTo.first_name[0]}. {trade.proposedTo.last_name}</div>
                <div style={{height:'30px',width:'2px',backgroundColor:'#000',justifySelf:'center'}} />
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',borderTop:'1px solid #000',padding:'5px',fontWeight:'500'}}>{trade.proposer.first_name[0]}. {trade.proposer.last_name}</div>
            </div>
        </div>
    );
}

const Proposals = props => {
    const { isLoading, error, data } = useQuery('proposals',() => getTrades(props.id,'proposals'));
    return (
        (isLoading ? <LeagueLoadingSpinner/> :
        <div style={{overflow:'scroll',marginBottom:'40px'}}>
            {data ? 
            <Row gutter={50}>
            {data.map((trade) => (
                <Col xs={{span:12}} sm={{span:4}}><TradeCard trade={trade} type="proposal" /></Col>
            ))}
            </Row>
            : <center><h2 style={{fontWeight:'500'}}>😑 You have no trade proposals.</h2></center> }
        </div>
        )
    );
}

const Proposed = props => {
    const { isLoading, error, data } = useQuery('proposed',() => getTrades(props.id,'proposed'));
    return (
        (isLoading ? <LeagueLoadingSpinner/> :
            <div style={{overflow:'scroll',marginBottom:'40px'}}>
                {data ? 
                <Row gutter={50}>
                {data.map((trade) => (
                    <Col xs={{span:12}} sm={{span:4}}><TradeProposedCard trade={trade} type="proposeds" /></Col>
                ))}
                </Row>
                : <center><h2 style={{fontWeight:'500'}}>😑 You have no trades proposed.</h2></center> }
            </div>
        )
    );
}

function Trades({match,location}) {
    const { params: { id } } = match;
    const [tab,setTab] = useState('proposals');
    return (
        <>
            <Nav league={true}/>
            <LeagueNav id={id} current="trades" />
            <div className="league-main">  
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',flexWrap:'wrap'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <h1>Trades</h1>
                        <select className="proposal-select" onChange={(e) => setTab(e.target.value)}>
                            <option value="proposals">Proposals</option>
                            <option value="proposed">Proposed</option>
                        </select>
                    </div>
                    <button className="propose-a-trade-btn" onClick={() => window.location.href=`/league/${id}/trades/propose`}>Propose A Trade</button>
                </div>
                {tab === 'proposals' ? <Proposals id={id}/> : <Proposed id={id}/>}
            </div>
        </>
    );
}

export default Trades;