import { useState, useEffect } from 'react';
import DraftNav from 'components/nav/DraftNav';
import DraftInfo from 'components/draft/DraftInfo';
import DraftMessage from 'components/draft/DraftMessage';
import Cookies from 'js-cookie';
import 'stylesheets/pages/Draft.css';
import DraftMain from 'components/draft/DraftMain';
import { initiateSocket, disconnectSocket, subscribeToChat, loadInitialChat } from 'api/ChatAPI.js';
import {getDraft,getDraftInfo,autodraft} from 'api/DraftAPI';
import {useQuery,useMutation} from 'react-query';
import LoadingSpinner from 'components/LoadingSpinner';

function Draft(props) {
    const [tab,setTab] = useState('players');
    const [chat, setChat] = useState([]);
    useEffect(() => {
        initiateSocket(props.id);
        loadInitialChat((err, data) => {
            if(err) return;
            setChat(data);
        });
        subscribeToChat((err, data) => {
            if(err) return;
            setChat(oldChats =>[...oldChats, data])
        });
        return () => {
            disconnectSocket();
        }
    }, []);
    const { isLoading:draft_init_loading, isError, data:draft_init_data, error } = useQuery('draft_init', () => getDraft(props.id));
    const { isLoading:draft_data_loading, infoIsError, data:draft_data, info_error,refetch } = useQuery('draft_info', () => getDraftInfo(props.id),{refetchInterval: 3000});
    
    if (draft_init_loading || draft_data_loading) return <LoadingSpinner>Loading Players...</LoadingSpinner>;
    return (
        <>
            <div className="draft-before">
                <DraftNav />
                <DraftInfo id={props.id} picking={draft_data.picking} picks={draft_init_data.picks} pick={draft_data.overall_pick} round={draft_data.round} draftTime={draft_data.next_pick_expiry} refetch={refetch}/>
                <div className="draft-in-nav" style={{backroundColor:'#F1F1F1'}}>
                    <div className="draft-sub-nav">
                        <button id="players" className={`draft-nav-btn ${tab === 'players' ? 'active' : ''}`} style={{marginLeft:'15px'}} onClick={() => setTab('players')}>Players</button>
                        <button id="myteam" className={`draft-nav-btn ${tab === 'myteam' ? 'active' : ''}`} style={{marginLeft:'15px'}} onClick={() => setTab('myteam')}>My Team</button>
                        <button id="results" className={`draft-nav-btn ${tab === 'results' ? 'active' : ''}`} style={{marginLeft:'15px'}} onClick={() => setTab('results')}>Draft Results</button>
                    </div>
                </div>
            </div>
            <DraftMain id={props.id} team_id={draft_init_data.team_id} tab={tab} picking={draft_data.picking} players={draft_init_data.players} availablePlayers={draft_data.available_ids} edge={draft_init_data.edge}/>
            {/* <DraftMessage id={props.id} picking={draft_data.picking} chat={chat}/> */}

        </>
    );
}

export default Draft;