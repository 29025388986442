import Players from 'components/draft/Players.js';
import Results from 'components/draft/Results.js';
import Rosters from 'components/draft/Rosters.js';
import Teams from 'components/draft/Teams';
import {useEffect} from 'react';

function DraftMain(props) {
    if (props.tab === 'players') {
        return <Players id={props.id} picking={props.picking} availablePlayers={props.availablePlayers} autodraft={props.autodraft} players={props.players} edge={props.edge}/>
    } else if (props.tab === 'results') {
        return <Results id={props.id} />
    } else if (props.tab === 'myteam') {
        return <Teams team_id={props.team_id} id={props.id} edge={props.edge}/>
    }
}

export default DraftMain;