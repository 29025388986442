import {useState} from 'react';
import {useQuery} from 'react-query';
import {teams} from 'api/DraftAPI';

const PlayerStats = props => {
    if (props.filter === '2020') {
        var stats = props.player.stats['2020'];
    } else if (props.filter === '2019') {
        var stats = props.player.stats['2019'];
    } else if (props.filter === '2021') {
        var stats = props.player.projections['2021'];
    } else if (props.filter === 'weekly') {
        var stats = props.player.projections.week;
    }
    return (
        <>
        {stats ?
        <>
        <td style={{textAlign:'center'}}>{stats.goals}</td>
        <td style={{textAlign:'center'}}>{stats.twoPointGoals}</td>
        <td style={{textAlign:'center'}}>{stats.assists}</td>
        <td style={{textAlign:'center'}}>{stats.groundBalls}</td>
        <td style={{textAlign:'center'}}>{stats.turnovers}</td>
        <td style={{textAlign:'center'}}>{stats.causedTurnovers}</td>
        <td style={{textAlign:'center',minWidth:'80px'}}>{stats.savePct.toFixed(2)}</td>
        <td style={{textAlign:'center',minWidth:'90px'}}>{stats.faceoffPct.toFixed(2)}</td>
        </>
        : 
        <>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        </>
        }
        </>
    );
}

function Teams(props) {
    const [statsFilter,setStatsFilter] = useState('2020');
    const [viewing,setViewing] = useState(props.team_id);
    const { isLoading, infoIsError, data, info_error,refetch } = useQuery('my_team', () => teams(props.id));
    if (isLoading) return <center><h2>Loading Teams...</h2></center>;
    return (
        <>
        <div style={{marginTop:'10px',marginBottom:'10px',width:'100%',display:'flex',justifyContent:'flex-end'}}>
             <select className="draft-sort-select" style={{marginRight:'10px'}} onChange={(e) => setViewing(e.target.value)}>
                <option value={props.team_id}>Your Team</option>
                {Object.keys(data).map((team) => (
                    (team !== props.team_id ?
                    <option value={team}>{data[team]['name']}</option>
                    : null)
                ))}
            </select>
            <select className="draft-sort-select" style={{marginRight:'10px'}} onChange={(e) => setStatsFilter(e.target.value)}>
                <option value="2020">2020 Stats</option>
                <option value="2019">2019 Stats</option>
                <option value="2021" disabled={!props.edge ? true : null}>2021 Proj. Stats</option>
                <option value="weekly" disabled={!props.edge ? true : null}>Weekly Proj. Stats</option>
            </select>
        </div>
        <div style={{overflow:'scroll',position:'absolute',width:'100%'}}>
            <table style={{width:'100%',border: '2px solid black',borderCollapse: 'collapse',minWidth:'700px'}}>
                <thead>
                    <tr style={{border: '2px solid black'}}>
                        <th style={{border: '2px solid black'}} colspan="2" scope='colgroup'>PLAYERS</th>
                        <th style={{border: '2px solid black'}} colspan="2" scope='colgroup'>BASICS</th>
                        <th style={{border: '2px solid black'}} colspan="8" scope='colgroup'>STATS <span style={{textTransform:'capitalize'}}>({statsFilter})</span></th>
                    </tr>
                    <tr >
                        <th style={{border: '2px solid black'}} scope='col'>POS</th>
                        <th style={{border: '2px solid black'}} scope='col'>Player</th>
                        <th style={{border: '2px solid black'}} scope='col'>Rk</th>
                        <th style={{border: '2px solid black'}} scope='col'>Pts/Game</th>
                        <th style={{border: '2px solid black',minWidth:'20px'}} scope='col'>G</th>
                        <th style={{border: '2px solid black',minWidth:'40px'}} scope='col'>2PT G</th>
                        <th style={{border: '2px solid black',minWidth:'30px'}} scope='col'>A</th>
                        <th style={{border: '2px solid black'}} scope='col'>GB</th>
                        <th style={{border: '2px solid black',minWidth:'30px'}} scope='col'>T</th>
                        <th style={{border: '2px solid black'}} scope='col'>CT</th>
                        <th style={{border: '2px solid black'}} scope='col'>Save %</th>
                        <th style={{border: '2px solid black'}} scope='col'>Faceoff %</th>
                    </tr>
                </thead>
                <tbody style={{width:'100%'}}>
                    {data[viewing].players.map((player) => (
                        <tr id={player.officialId}>
                            <td style={{textAlign:'center'}}>{player.position}</td>
                            <td style={{textAlign:'center'}}><span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={player.profileUrl} className="draft-player-img" style={{height:'40px',borderRadius:'20px',marginRight:'3px'}} />{player.firstName} {player.lastName}</span></td>
                            <td style={{textAlign:'center'}}>{player.rank}</td>
                            <td style={{textAlign:'center'}}>{(player.prevSeasonScore/player.prevSeasonGamesPlayed).toFixed(1)}</td>
                            <PlayerStats player={player} filter={statsFilter} />
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </>
    );
}

export default Teams;