import Cookies from 'js-cookie';
import Nav from 'components/nav/Nav';
import prism_logo from 'static/images/Prism.svg';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import  'stylesheets/pages/PrismDashboard.css';
import {useState, useEffect} from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import up from 'static/icons/up.svg';
import down from 'static/icons/down.svg';


function PrismDashboard(props) {
    const [loading,setLoading] = useState(false);
    const [pll,setPll] = useState([]);
    const [nll,setNll] = useState([]);
    const [undervalued,setUndervalued] = useState([]);
    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch(`/api/prism/dashboard`, requestOptions).then(async response => {
            const data = await response.json();
            setPll(data.pll);
            setNll(data.nll)
            setUndervalued(data.undervalued);
            setLoading(false);
        });
    }, []);
    return (
        <>
        <Nav />
        {loading ? 
        <div>
            <LoadingSpinner message="Hold on a sec, we're doing some math..."/>
        </div> :
        <div className="main">
            <Row>
                <div>
                    <img className="prism-brand-dashboard" src={prism_logo} />
                </div>
            </Row>
            <Row style={{height:'100%'}} gutter={40}>
                <Col xs={12} sm={4}>
                    <div className="prism-card">
                        <div className="prism-card-title">
                            Top PLL Players
                        </div>
                        <div className="players-header">
                            <div className="col-header">Name</div>
                            <div className="col-header">Proj.</div>
                            <div className="col-header">Trend</div>
                        </div>
                        <div className="players">
                        {pll.slice(0, 7).map(player => (
                            <div className="player-card">
                                <div className="player-card-name">{player.firstName} {player.lastName}</div>
                                <div className="player-card-proj">{player.pred.overall.toFixed(2)}</div>
                                <div className="player-card-trend">{player.pred.longTerm > 0 ? <img src={up} /> : <img src={down}/>}</div>
                            </div>
                        ))}
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div className="prism-card">
                        <div className="prism-card-title">
                            Top NLL Players
                        </div>
                        <div className="players-header">
                            <div className="col-header">Name</div>
                            <div className="col-header">Proj.</div>
                            <div className="col-header">Trend</div>
                        </div>
                        <div className="players">
                        {nll.slice(0, 7).map(player => (
                            <div className="player-card">
                                <div className="player-card-name">{player.firstName} {player.lastName}</div>
                                <div className="player-card-proj">{player.pred.overall.toFixed(2)}</div>
                                <div className="player-card-trend">{player.pred.longTerm > 0 ? <img src={up} /> : <img src={down}/>}</div>
                            </div>
                        ))}
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <div className="prism-card">
                        <div className="prism-card-title">
                            Undervalued
                        </div>
                        <div className="players-header">
                            <div className="col-header">Name</div>
                            <div className="col-header">Proj.</div>
                            <div className="col-header">Trend</div>
                        </div>
                        <div className="players">
                        {undervalued.splice(0, 7).map(player => (
                            <div className="player-card">
                                <div className="player-card-name">{player.firstName} {player.lastName}</div>
                                <div className="player-card-proj">{player.pred.overall.toFixed(2)}</div>
                                <div className="player-card-trend">{player.pred.longTerm > 0 ? <img src={up} /> : <img src={down}/>}</div>
                            </div>
                        ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        }
        </>
    );
}

export default PrismDashboard;