import React from "react"
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route 
        {...rest}
        render={props =>
            Cookies.get('token') ? (
                <Component {...props} />
            ) : (
                <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`}/>
            )
        }
    />
);

export { AuthenticatedRoute };
