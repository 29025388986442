import 'stylesheets/pages/Home.css';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";

function HomeFooter(props) {
    return (
        <div className="home-footer">
            <Row>
                <Col span={3}>
                    <div className="home-footer-col">
                        <p className="home-footer-col-header">Help</p>
                        <a className="footer-link" href="http://docs.flowfantasy.com" target="_blank">Knowledge Base</a>
                        <a className="footer-link" href="mailto:support@flowfantasy.com">Email</a>
                        <a className="footer-link" href="https://www.instagram.com/flowfantasyinc/">Instagram</a>
                        <a className="footer-link" href="https://twitter.com/FlowFantasyInc">Twitter</a>
                    </div>
                </Col>
                <Col span={3}>
                    <div className="home-footer-col">
                        <p className="home-footer-col-header">Company</p>
                        <a>About</a>
                    </div>
                </Col>
                <Col span={3}>
                    <div className="home-footer-col">
                        <p className="home-footer-col-header">Stats</p>
                        <a>Coming Soon...</a>
                    </div>
                </Col>
                <Col span={3}>
                    <div className="home-footer-col">
                        <p className="home-footer-col-header">Legal</p>
                        <a className="footer-link" href="/tos" target="_blank">Terms of Service</a>
                        <a className="footer-link" href="/privacypolicy" target="_blank">Privacy Policy</a>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop:'30px'}}>
                <Col span={12}>
                <center>
                    <div className="grow-the-flow">#GrowTheFlow</div>
                    <div>Made in New York.</div>
                    <div>Copyright © 2021 Flow Fantasy Inc. All rights reserved.</div>
                </center>
                </Col>
            </Row>
        </div>
    );
}

export default HomeFooter;