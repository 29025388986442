import logo from 'static/images/logo.png';
import 'stylesheets/nav/PreNav.css';

function PreNav(props) {
    return (
        <div className="nav">
            <a href="/">
            <div className="brand">
                <img className="logo" src={logo} alt="logo" />
                <h1 className="brand-name">Flow Fantasy</h1>
            </div>
            </a>
        </div>
    );

}

export default PreNav;