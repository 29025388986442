import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Draft from 'pages/Draft';
import LeagueDashboard from 'pages/league/LeagueDashboard';

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}
function getUrlParam(parameter, defaultvalue){
    var urlparameter = defaultvalue;
    if(window.location.href.indexOf(parameter) > -1){
        urlparameter = getUrlVars()[parameter];
        }
    return urlparameter;
}

function League({match, location}) {
    const { params: { id } } = match;
    const [leagueState,setLeagueState] = useState('predraft');
    const result = getUrlParam('new','false');
    let newLeague = false;
    if (result === 'true') {
        newLeague = true;
    }
    const checkLeagueStatus = () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
		};
		 fetch(`/api/leagues/${id}/state`, requestOptions).then(async response => {
            const data = await response.json();
            setLeagueState(data['state']);
		 });
    }
    useEffect(() => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
		    };
		 fetch(`/api/leagues/${id}/state`, requestOptions).then(async response => {
			 const data = await response.json();
			 // check for error response
			 if (response.status === 400) {
                 // get error message from body or default to response statusText
                 Cookies.remove('token');
				 window.location.href = '/login';
             } else if (response.status === 404 || response.status === 403) {
                window.location.href = '/leagues';
             } else {
                setLeagueState(data['state']);
			 }
         });
         checkLeagueStatus()
         const interval = setInterval(() => checkLeagueStatus(), 5000)
         return () => {
           clearInterval(interval);
         }
    }, []);
    if (leagueState === 'draft') {
        return (
            <Draft id={id} leagueState={leagueState} />
        );  
    }
    return (
        <LeagueDashboard id={id} leagueState={leagueState} />
    );
}

export default League;