
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from 'react';
import Loader from 'react-loader-spinner';
import 'stylesheets/LeagueNav.css';
function LeagueLoadingSpinner(props) {
  //other logic
     return(
    <div className="league-loader">
      <center>
      <Loader
         type="Oval"
         color="#99ccff"
         height={50}
         width={50}
         timeout={50000} //3 secs
 
      />
      {props.message ? <p style={{fontSize:'20px',fontWeight:'600'}}>{props.message}</p> : null}
      </center>
      </div>
     );
 }
 export default LeagueLoadingSpinner;
