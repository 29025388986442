import {useState, useEffect} from 'react';
import Nav from 'components/nav/Nav';
import DailyNav from 'components/daily/DailyNav';
import Cookies from 'js-cookie';
import { Row, Col } from 'react-simple-flex-grid';
import LoadingSpinner from 'components/LoadingSpinner';

function DailyLineupView({match}) {
    const { params: { lineup_id } } = match;
    const [lineup, setLineup] = useState({});
    const loadLineup = async () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        const response = await fetch(`/api/daily/lineups/${lineup_id}`, requestOptions);
        const data = await response.json();
        console.log(data);
        setLineup(data);
    }
    const getRecent = (recent) => {
        if (recent) {
            if (recent > 0) {
                var recent_class = 'positive';
                var prior_value = '';
            } else if (recent === 0) {
                var recent_class = 'neutral';
                var prior_value = '';
            } else {
                var recent_class = 'negative';
                var prior_value = '';
            }
            return <span className={recent_class}>{prior_value}{recent}</span>;
        } else {
            return null;
        }
    }
    useEffect(() => {
        loadLineup()
    }, []);
    return (
        <>
            <Nav />
            <DailyNav />
            <div className="daily_main">
                {lineup.owner ?

                    <>
                    <h1 style={{marginBottom:'0px'}}>{lineup.owner}'s Lineup</h1>
                    <h3 style={{marginTop:'0px',fontWeight:'500'}}>Created {lineup.date_created}</h3>
                    </>
                : null }
            </div>
            {lineup.attack ? <Row>
                <Col xs={12} sm={{span:6,offset:3}} style={{paddingBottom:'20px'}}>
                    
                    <div className="daily-lineup-card">
                        <center><div className="daily-lineup-card-header">{lineup.date_created}</div></center>
                        <hr />
                        <div className="daily-lineup-card-info">
                            <div className="daily-lineup-card-entries">
                                <p>ENTRIES</p>
                                <span className="daily-lineup-card-info-title">{lineup.entries}</span>
                            </div>
                            <div className="daily-lineup-card-points">
                                <p>TOTAL POINTS</p>
                                <span className="daily-lineup-card-info-title">{lineup.total_points}</span>
                            </div>
                        </div>
                        <table style={{width:'100%',borderSpacing: '0'}}>
                        <thead className="daily-card">
                            <tr>
                                <th className="daily-lineup-header">POS</th>
                                <th className="daily-lineup-header">PLAYER</th>
                                <th className="daily-lineup-header">TEAM</th>
                                <th className="daily-lineup-header">Proj.</th>
                                <th className="daily-lineup-header">Actual</th>
                            </tr>
                        </thead>
                        <tbody className="lineup-body">
                        <tr>
                            <td>ATT</td>
                            <td>{lineup['attack'][0] ? <span>{lineup['attack'][0].firstName} {lineup['attack'][0].lastName}</span> : null}</td>
                            {lineup['attack'][0] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['attack'][0].team_logo} /></td>
                                    <td>{(lineup['attack'][0]['2020SeasonScore'] / lineup['attack'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['attack'][0]['2020SeasonScore'] / lineup['attack'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['attack'][0]['2020SeasonScore'] / lineup['attack'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['attack'][0].player_id])}</td>

                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>ATT</td>
                            <td>{lineup['attack'][1] ? <span>{lineup['attack'][1].firstName} {lineup['attack'][1].lastName}</span> : null}</td>
                            {lineup['attack'][1] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['attack'][1].team_logo} /></td>
                                    <td>{(lineup['attack'][1]['2020SeasonScore'] / lineup['attack'][1]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['attack'][1]['2020SeasonScore'] / lineup['attack'][1]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['attack'][1]['2020SeasonScore'] / lineup['attack'][1]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['attack'][1].player_id])}</td>
                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>ATT</td>
                            <td>{lineup['attack'][2] ? <span>{lineup['attack'][2].firstName} {lineup['attack'][2].lastName}</span> : null}</td>
                            {lineup['attack'][2] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['attack'][2].team_logo} /></td>
                                    <td>{(lineup['attack'][2]['2020SeasonScore'] / lineup['attack'][2]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['attack'][2]['2020SeasonScore'] / lineup['attack'][2]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['attack'][2]['2020SeasonScore'] / lineup['attack'][2]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['attack'][2].player_id])}</td>
                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>M</td>
                            <td>{lineup['midfield'][0] ? <span>{lineup['midfield'][0].firstName} {lineup['midfield'][0].lastName}</span> : null}</td>
                            {lineup['midfield'][0] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['midfield'][0].team_logo} /></td>
                                    <td>{(lineup['midfield'][0]['2020SeasonScore'] / lineup['midfield'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['midfield'][0]['2020SeasonScore'] / lineup['midfield'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['midfield'][0]['2020SeasonScore'] / lineup['midfield'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['midfield'][0].player_id])}</td>
                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>M</td>
                            <td>{lineup['midfield'][1] ? <span>{lineup['midfield'][1].firstName} {lineup['midfield'][1].lastName}</span> : null}</td>
                            {lineup['midfield'][1] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['midfield'][1].team_logo} /></td>
                                    <td>{(lineup['midfield'][1]['2020SeasonScore'] / lineup['midfield'][1]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['midfield'][1]['2020SeasonScore'] / lineup['midfield'][1]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['midfield'][1]['2020SeasonScore'] / lineup['midfield'][1]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['midfield'][1].player_id])}</td>
                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>M</td>
                            <td>{lineup['midfield'][2] ? <span>{lineup['midfield'][2].firstName} {lineup['midfield'][2].lastName}</span> : null}</td>
                            {lineup['midfield'][2] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['midfield'][2].team_logo} /></td>
                                    <td>{(lineup['midfield'][2]['2020SeasonScore'] / lineup['midfield'][2]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['midfield'][2]['2020SeasonScore'] / lineup['midfield'][2]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['midfield'][2]['2020SeasonScore'] / lineup['midfield'][2]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['midfield'][2].player_id])}</td>
                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>FO</td>
                            <td>{lineup['faceoff'][0] ? <span>{lineup['faceoff'][0].firstName} {lineup['faceoff'][0].lastName}</span> : null}</td>
                            {lineup['faceoff'][0] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['faceoff'][0].team_logo} /></td>
                                    <td>{(lineup['faceoff'][0]['2020SeasonScore'] / lineup['faceoff'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['faceoff'][0]['2020SeasonScore'] / lineup['faceoff'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['faceoff'][0]['2020SeasonScore'] / lineup['faceoff'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['faceoff'][0].player_id])}</td>
                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>D</td>
                            <td>{lineup['defense'][0] ? <span>{lineup['defense'][0].name}</span> : null}</td>
                            {lineup['defense'][0] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['defense'][0].team_logo} /></td>
                                    <td>{(lineup['defense'][0]['2020SeasonScore'] / lineup['defense'][0]['stats']['2020']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['defense'][0].player_id])}</td>

                                </>
                            : null }
                        </tr>
                        <tr>
                            <td>G</td>
                            <td>{lineup['goalie'][0] ? <span>{lineup['goalie'][0].firstName} {lineup['goalie'][0].lastName}</span> : null}</td>
                            {lineup['goalie'][0] ?
                                <>
                                    <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['goalie'][0].team_logo} /></td>
                                    <td>{(lineup['goalie'][0]['2020SeasonScore'] / lineup['goalie'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['goalie'][0]['2020SeasonScore'] / lineup['goalie'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['goalie'][0]['2020SeasonScore'] / lineup['goalie'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                    <td>{getRecent(lineup['recent'][lineup['goalie'][0].player_id])}</td>
                                </>
                            : null }
                        </tr>
                        </tbody>
                        </table>
                        <div className="lineup-controls" style={{justifyContent:'flex-end'}}>
                            <div className="daily-lineup-recent-total">Total: <span className={`${lineup.recent_sum > 0 ? 'positive' : (lineup.recent_sum === 0 ? null : 'negative')}`}>{lineup.recent_sum}</span></div>
                        </div>
                    </div>
                </Col>  
            </Row> : <LoadingSpinner /> }
        </>
    );
}

export default DailyLineupView;