import {useState,useEffect} from 'react';
import 'stylesheets/pages/Home.css';
import HomeNav from 'components/nav/HomeNav';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import daily from 'static/icons/daily.svg';
import daily_img from 'static/images/daily-home.png';
import gives from 'static/icons/gives.svg';
import {PageView, initGA, Event} from 'helpers/Tracking';
import HomeFooter from 'components/HomeFooter';

function Home(props) {
    useEffect(() => {
        initGA('UA-161394853-1');
        PageView();
        document.addEventListener("scroll", () => {
            if (window.scrollY < 100) {
                setOpaque(false);
            } else {
                setOpaque(true);
            }

        });
    }, []);
    const [opaque, setOpaque] = useState(false);
    return (
        <div className="home">
            <HomeNav opaque={opaque} />
            <Row style={{marginTop:'80px'}}>
            <Col xs={12} sm={{span:6,offset:3}}>
                <center>
                <section class="section-process">
                    <div class="section-container">
                        <div class="process-steps-container container-medium with-padding">
                            <div class="process-step-container process-step-1">
                                <div class="process-step-title-container">
                                    <h1 class="process-step-title">Fantasy</h1>
                                    <div class="process-step-title-overlay">Fantasy</div>
                                </div>
                            </div>
                            <div class="process-step-container process-step-2">
                                <div class="process-step-title-container">
                                    <h1 class="process-step-title">Lacrosse</h1>
                                    <div class="process-step-title-overlay">Lacrosse</div>
                                </div>
                            </div>
                            <div class="process-step-container process-step-3">
                                <div class="process-step-title-container">
                                    <h1 class="process-step-title">Now</h1>
                                    <div class="process-step-title-overlay">Now</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </center>
            </Col>
            </Row>
            <center>
                <div className="cta">
                    <Link to="/signup">
                        <button className="cta-btn get-started" onClick={() => Event("CTA",'Get Started clicked',"HOME_PAGE")}>Get Started</button>
                    </Link>
                    <Link to="/login">
                        <button className="cta-btn login" onClick={() => Event("CTA",'Login clicked',"HOME_PAGE")}>Login</button>
                    </Link>
                </div>
            </center>
            <Row>
            <Col sm={{span:10,offset:1}}>
            <center>
            <div className="post-cta-callout">
                Flow Fantasy is the world's first and only fantasy lacrosse platform. Play fantasy lacrosse without the hastle.
            </div>
            </center>
            </Col>
            </Row>
            <Row style={{marginTop:'80px'}}>
                <Col xs={{span:12}} sm={{span:10,offset:1}}>
                    <center>
                        <div className="home-explore">
                            EXPLORE FLOW FANTASY
                        </div>
                    </center>
                </Col>
            </Row>
            <Row style={{marginTop:'30px'}}>
                <Col sm={{span:10,offset:1}}>
                    <center>
                        <h1 className="headline" style={{fontFamily:'Inter',fontSize:'50px'}}>Fantasy for the <span style={{color:'#ffcb06'}}>PLL</span></h1>
                    </center>
                    <Row>
                        <Col xs={{span:12}} sm={{span:6}}>
                            <p className="paragraph">Create a fantasy league and compete with friends for the duration of the PLL season.</p>
                        </Col>
                        <Col xs={{span:12}} sm={{span:6}}>
                            <p className="paragraph">Invite friends, hold a draft, set your lineup, make trades, and compete.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} offset={0}>
                            <center>
                            <h1 style={{fontFamily:'Inter'}}>We handle the hard stuff. You worry about your team.</h1>
                            <p className="paragraph">Flow Fantasy has automated scoring and uses exact statistics.</p>
                            </center>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        <center><button className="pll-gets-started" onClick={() => {window.location.href='/signup';Event("CTA",'Daily get started clicked',"HOME_PAGE")}} target="_blank">Get Started</button></center>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop:'80px'}}>
                <Col sm={{span:10,offset:1}}>
                    <center>
                        <div className="daily">
                            <div className="daily-brand"><img src={daily} style={{height:'65px'}} />Daily</div>
                        </div>
                        <div  >Daily Fantasy for D1 Lacrosse</div>
                        </center>
                        <Row>
                            <Col xs={{span:12}} sm={{span:6}}>
                                <p className="paragraph">Competition is held through contests into which you can entire a lineup comprised of players from the <span className="acc">ACC</span>, <span className="big-ten">Big Ten</span>, and <span className="big-east">Big East</span>.</p>
                            </Col>
                            <Col xs={{span:12}} sm={{span:6}}>
                                <p className="paragraph">Participate in official contests or create your own public or private contests to compete with your friends over a given period of time (we recommend weekends).</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{span:12}} sm={{span:8}}>
                                <img src={daily_img} height={500} style={{marginLeft:'-40px'}} />
                            </Col>
                            <Col xs={{span:12}} sm={{span:4}}>
                                <div className="side-bar">
                                <h2 style={{marginBottom:'0px'}}>Automated Scoring</h2>
                                <p className="paragraph" style={{marginTop:'0px',paddingLeft:'0px'}}>All scores are automatically tallied after each game.</p>
                                </div>
                                <div className="side-bar">
                                <h2 style={{marginBottom:'0px'}}>Big Money</h2>
                                <p className="paragraph" style={{marginTop:'0px',paddingLeft:'0px'}}>Create your lineup with a salary cap of $50000. Players are priced proportional to their performance.</p>
                                </div>
                                <div className="side-bar">
                                <h2 style={{marginBottom:'0px'}}>Lineups Lineups Lineups</h2>
                                <p className="paragraph" style={{marginTop:'0px',paddingLeft:'0px'}}>Add players from the ACC, Big Ten, and Big East to make your ultimate lineup.</p>
                                </div>
                            </Col>
                        </Row>
                        <center><button className="daily-gets-started" onClick={() => {window.location.href='/signup';Event("CTA",'Daily get started clicked',"HOME_PAGE")}} target="_blank">Get Started</button></center>
                </Col>
            </Row>
            <center><div className="headline" style={{fontFamily:'Inter',fontSize:'50px',marginTop:'80px'}}>More Coming Soon...</div></center>
            <HomeFooter />
        </div>
    );
}

export default Home;