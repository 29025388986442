import {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import 'stylesheets/ContestView.css';
import ShareContest from 'components/daily/ShareContest';
import delete_icon from 'static/icons/delete.svg';

function ContestView(props) {
    const [contest, setContest] = useState();
    const [entries, setEntries] = useState([]);
    const [search, setSearch] = useState('');
    const loadContest = async () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        const response = await fetch(`/api/daily/contests/${props.current}/details`, requestOptions);
        const data = await response.json();
        setContest(data);
        setEntries(data.details)
        console.log(entries)
    }
    useEffect(() => {
        if (props.current) {
            loadContest();
            
        }
    }, [props.current]);
    useEffect(() => {
        if (contest) {
            if (search.trim() === '') {
                setEntries(contest.details)
            } else {
                setEntries(contest.details.filter(entry => entry.user.startsWith(search)));
                
            }
        }
    }, [search]);
    const normalize_date = (date_str) => {
        var newDate = new Date(date_str);
        var hours = newDate.getHours();
        var minutes = newDate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()} ${strTime}`;
        return final_date_str;  

    }
    const delete_entry = (entry_id) => {
        let remove = new FormData();
        remove.append('entry',entry_id);
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
            method: 'DELETE',
            body: remove
        };
        fetch(`/api/daily/contests/${props.current}`, requestOptions).then( async response => {
            if (response.ok) {
                console.log('in')
                var fresh = entries.filter(function( obj ) {
                    return obj._id !== entry_id;
                });
                console.log(fresh)
                setEntries(fresh);
            }
        });
    }
    return (
        <>
        <div className="contest-view-header">
            <h1 className="contest-name">{contest ? contest.name : null}</h1>
            <h2 className={`contest-status ${contest ? contest.status.replace(/ /g, '') : null}`}>{contest ? contest.status : null}</h2>
        </div>
        <div className="contest-view-subheader">
            <div>
            <h2 className="contest-start">Start: {contest ? normalize_date(contest.start) : null}</h2>
            <h2 className="contest-end">End: {contest ? normalize_date(contest.end) : null}</h2>
            </div>
            {contest ? (contest.status !== 'Complete' ? <ShareContest code={contest.code} id={contest._id} /> : <div className="winner-winner-chicken-dinner">{entries[0] ? `👑 ${entries[0].user} - ${entries[0].score} pts`: null}</div> ) : null}
        </div>
        <hr />
        <div className="contest-entries">
            <p className="daily-view-entries-header">Entries</p>
            <input placeholder="Search" className="contest-search" value={search} onChange={(e) => setSearch(e.target.value)} />
            <div className="daily-join-entrants">
                { entries ? entries.map((entry,index) => (
                    <div style={{marginBottom:'5px'}}>{entry.rank}{entry.rank === 1 ? 'st' : (entry.rank === 2 ? 'nd' : (entry.rank === 3 ? 'rd' : 'th'))} - {entry.user} - <span className={entry.score === 0 ? 'neutral' : (entry.score > 0 ? 'positive' : 'negative')}>{entry.score} pts</span> - <a className="user-lineup" href={`/daily/lineups/${entry.lineup}`}>Lineup</a> {contest.admin === true && entry.admins_team === false && contest.status === 'Pre Game' ? <button className="delete-entrant" onClick={() => delete_entry(entry._id)}>Delete Entrant</button> : null}</div>
                )) : null}
            </div>
        </div>
        </>
    );
}

export default ContestView;