import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import LoadingSpinner from 'components/LoadingSpinner';
import {getMatchups} from 'api/LeagueAPI';
import {useQuery} from 'react-query';
import 'stylesheets/Matchups.css';

const week_map = {0:'Jun 4 - Jun 6',1:'Jun 11 - Jun 13',2:'Jun 25 - Jun 27',3:'Jul 2 - Jul 4',4:'Jul 9 - Jul 11',5:'Jul 30 - Aug 1',6:'Aug 13 - Aug 15'}

const AllStarWeekend = () => {
    return (
        <div style={{backgroundColor:'#99ccff',color:'#fff',height:'50px',display:'flex',alignItems:'center',borderRadius:'10px',marginBottom:'15px',minWidth:'350px',maxWidth:'500px'}}>
            <h2 style={{marginLeft:'10px'}} className="all-star-week-header">All Star Weekend • Jul 18</h2>
        </div>
    );
}

const WeekCard = props => {
    return (
        <>
        <div style={{border:'3px solid #cbccce',borderRadius:'10px',marginBottom:'15px',minWidth:'350px',maxWidth:'500px'}}>
            <h2 className="matchup-week-header" style={{marginLeft:'10px'}}>Week {props.index+1} • {week_map[props.index]}</h2>
            <div>
                <table style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th>Home Team</th>
                            <th>Score</th>
                            <th>Score</th>
                            <th>Away Team</th>
                        </tr>
                    </thead>
                    <tbody style={{width:'100%'}}>
                    {props.week.map((matchup) => (
                        <tr>
                            <td style={{textAlign:'center'}}><a href={`/teams/${matchup[0]._id}`} style={{color:"#000"}}>{matchup[0].name}</a></td>
                            <td>{matchup[0]['weekly_scores'].recent}</td>
                            <td>{matchup[1]['weekly_scores'].recent}</td>
                            <td style={{textAlign:'center'}}><a href={`/teams/${matchup[1]._id}`} style={{color:"#000"}}>{matchup[1].name}</a></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
        {props.index === 4 ? <AllStarWeekend /> : null}
        </>
    );
}

function Matchups({match, location}) {
    const { params: { id } } = match;
    const { isLoading, error, data } = useQuery('matchups',() => getMatchups(id));
    console.log(error)
    return (
        (isLoading ? <LoadingSpinner/> :
            <>
                <Nav league={true}/>
                <LeagueNav current="matchups" id={id}/>
                <div className="league-main" style={{marginRight:'0px',paddingRight:'0px'}}>
                    <h1>Matchups</h1>
                    <div style={{overflow:'auto'}}>
                        {!data.error ?
                            data.schedule.map((week,index) => (
                                <WeekCard week={week} currentWeek={data.week} id={id} index={index}/>
                            ))
                        : <h2 style={{margin:'0px',fontWeight:'400'}}>Matchups will be generated after your draft.</h2>}
                    </div>
                </div>
            </>
        )
    );
}

export default Matchups;