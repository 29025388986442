import { useState, useEffect } from 'react';
import Nav from 'components/nav/Nav';
import DailyNav from 'components/daily/DailyNav.js';
import 'stylesheets/DailyLineups.css';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import CreateLineup from 'components/daily/CreateLineup';
import Cookies from 'js-cookie';
import LoadingSpinner from 'components/LoadingSpinner';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import unlocked from 'static/icons/unlocked.svg';
import locked from 'static/icons/locked.svg';
import ReactTooltip from 'react-tooltip';
import {Event} from 'helpers/Tracking';

function DailyLineups(props) {
    const [lineups, setLineups] = useState([])
    const [editing, setEditing] = useState();
    useEffect(() => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch('/api/daily/lineups/me', requestOptions).then(async response => {
            var data = await response.json();
            setLineups(data);
		 }).catch(() => {
            console.log('Error!')
        });
    }, []);

    const getRecent = (recent) => {
        if (recent) {
            if (recent > 0) {
                var recent_class = 'positive';
                var prior_value = '';
            } else if (recent === 0) {
                var recent_class = 'neutral';
                var prior_value = '';
            } else {
                var recent_class = 'negative';
                var prior_value = '';
            }
            return <span className={recent_class}>{prior_value}{recent}</span>;
        } else {
            return null;
        }
    }
    return (
        <>
            <Nav />
            <DailyNav active={'lineups'} />
            <div className="daily_main">
                <div className="lineups-header"> 
                    <h1>My Lineups</h1>
                    <a onClick={() => {Event('DAILY','Create New Lineup','DAILY')}} href="/daily/newLineup" className="create-lineup" style={{color:'#fff'}}>Create Lineup +</a>
                </div>
                <div className="lineups-main">
                    <Row>
                    {lineups ? 
                        (lineups.map((lineup) => (
                          <Col xs={12} sm={4} style={{paddingBottom:'20px'}}>
                            <div className="daily-lineup-card">
                                <center><div className="daily-lineup-card-header">{lineup.date_created}</div></center>
                                <hr />
                                <div className="daily-lineup-card-info">
                                    <div className="daily-lineup-card-entries">
                                        <p>ENTRIES</p>
                                        <span className="daily-lineup-card-info-title">{lineup.entries}</span>
                                    </div>
                                    <div className="daily-lineup-card-points">
                                        <p>TOTAL POINTS</p>
                                        <span className="daily-lineup-card-info-title">{lineup.total_points}</span>
                                    </div>
                                </div>
                                <table style={{width:'100%',borderSpacing: '0'}}>
                                <thead className="daily-card">
                                    <tr>
                                        <th className="daily-lineup-header">POS</th>
                                        <th className="daily-lineup-header">PLAYER</th>
                                        <th className="daily-lineup-header">TEAM</th>
                                        <th className="daily-lineup-header">Proj.</th>
                                        <th className="daily-lineup-header">Actual</th>
                                    </tr>
                                </thead>
                                <tbody className="lineup-body">
                                <tr>
                                    <td>ATT</td>
                                    <td>{lineup['attack'][0] ? <span>{lineup['attack'][0].firstName} {lineup['attack'][0].lastName}</span> : null}</td>
                                    {lineup['attack'][0] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['attack'][0].team_logo} /></td>
                                            <td>{(lineup['attack'][0]['2020SeasonScore'] / lineup['attack'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['attack'][0]['2020SeasonScore'] / lineup['attack'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['attack'][0]['2020SeasonScore'] / lineup['attack'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['attack'][0].player_id])}</td>

                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>ATT</td>
                                    <td>{lineup['attack'][1] ? <span>{lineup['attack'][1].firstName} {lineup['attack'][1].lastName}</span> : null}</td>
                                    {lineup['attack'][1] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['attack'][1].team_logo} /></td>
                                            <td>{(lineup['attack'][1]['2020SeasonScore'] / lineup['attack'][1]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['attack'][1]['2020SeasonScore'] / lineup['attack'][1]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['attack'][1]['2020SeasonScore'] / lineup['attack'][1]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['attack'][1].player_id])}</td>
                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>ATT</td>
                                    <td>{lineup['attack'][2] ? <span>{lineup['attack'][2].firstName} {lineup['attack'][2].lastName}</span> : null}</td>
                                    {lineup['attack'][2] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['attack'][2].team_logo} /></td>
                                            <td>{(lineup['attack'][2]['2020SeasonScore'] / lineup['attack'][2]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['attack'][2]['2020SeasonScore'] / lineup['attack'][2]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['attack'][2]['2020SeasonScore'] / lineup['attack'][2]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['attack'][2].player_id])}</td>
                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>{lineup['midfield'][0] ? <span>{lineup['midfield'][0].firstName} {lineup['midfield'][0].lastName}</span> : null}</td>
                                    {lineup['midfield'][0] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['midfield'][0].team_logo} /></td>
                                            <td>{(lineup['midfield'][0]['2020SeasonScore'] / lineup['midfield'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['midfield'][0]['2020SeasonScore'] / lineup['midfield'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['midfield'][0]['2020SeasonScore'] / lineup['midfield'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['midfield'][0].player_id])}</td>
                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>{lineup['midfield'][1] ? <span>{lineup['midfield'][1].firstName} {lineup['midfield'][1].lastName}</span> : null}</td>
                                    {lineup['midfield'][1] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['midfield'][1].team_logo} /></td>
                                            <td>{(lineup['midfield'][1]['2020SeasonScore'] / lineup['midfield'][1]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['midfield'][1]['2020SeasonScore'] / lineup['midfield'][1]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['midfield'][1]['2020SeasonScore'] / lineup['midfield'][1]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['midfield'][1].player_id])}</td>
                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>{lineup['midfield'][2] ? <span>{lineup['midfield'][2].firstName} {lineup['midfield'][2].lastName}</span> : null}</td>
                                    {lineup['midfield'][2] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['midfield'][2].team_logo} /></td>
                                            <td>{(lineup['midfield'][2]['2020SeasonScore'] / lineup['midfield'][2]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['midfield'][2]['2020SeasonScore'] / lineup['midfield'][2]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['midfield'][2]['2020SeasonScore'] / lineup['midfield'][2]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['midfield'][2].player_id])}</td>
                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>FO</td>
                                    <td>{lineup['faceoff'][0] ? <span>{lineup['faceoff'][0].firstName} {lineup['faceoff'][0].lastName}</span> : null}</td>
                                    {lineup['faceoff'][0] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['faceoff'][0].team_logo} /></td>
                                            <td>{(lineup['faceoff'][0]['2020SeasonScore'] / lineup['faceoff'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['faceoff'][0]['2020SeasonScore'] / lineup['faceoff'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['faceoff'][0]['2020SeasonScore'] / lineup['faceoff'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['faceoff'][0].player_id])}</td>
                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>D</td>
                                    <td>{lineup['defense'][0] ? <span>{lineup['defense'][0].name}</span> : null}</td>
                                    {lineup['defense'][0] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['defense'][0].team_logo} /></td>
                                            <td>{(lineup['defense'][0]['2020SeasonScore'] / lineup['defense'][0]['stats']['2020']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['defense'][0].player_id])}</td>

                                        </>
                                    : null }
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>{lineup['goalie'][0] ? <span>{lineup['goalie'][0].firstName} {lineup['goalie'][0].lastName}</span> : null}</td>
                                    {lineup['goalie'][0] ?
                                        <>
                                            <td className="image-cropper" ><img className="daily-lineup-team-logo" src={lineup['goalie'][0].team_logo} /></td>
                                            <td>{(lineup['goalie'][0]['2020SeasonScore'] / lineup['goalie'][0]['stats']['2021']['gamesPlayed']) === Infinity ? (lineup['goalie'][0]['2020SeasonScore'] / lineup['goalie'][0]['stats']['2020']['gamesPlayed']).toFixed(0) : (lineup['goalie'][0]['2020SeasonScore'] / lineup['goalie'][0]['stats']['2021']['gamesPlayed']).toFixed(0)}</td>
                                            <td>{getRecent(lineup['recent'][lineup['goalie'][0].player_id])}</td>
                                        </>
                                    : null }
                                </tr>
                                </tbody>
                                </table>
                                <div className="lineup-controls" data-tip={lineup.locked ? "Your lineup is locked because it is in an active contest." : null}>
                                    <div className="lineup-sub-controls">
                                        <img className="lineup-locked" src={lineup.locked ? locked : unlocked}/>
                                        <button className={`edit-lineup ${lineup.locked ? 'locked' : ''}`} onClick={lineup.locked ? null : () => window.location.href='/daily/newLineup?edit='+lineup._id}>Edit</button>
                                    </div>
                                    {lineup.locked ? <ReactTooltip /> : null}
                                    <div className="daily-lineup-recent-total">Total: <span className={`${lineup.recent_sum > 0 ? 'positive' : (lineup.recent_sum === 0 ? null : 'negative')}`}>{lineup.recent_sum}</span></div>
                                </div>

                            </div>
                          </Col>  
                        )))
                     : <LoadingSpinner/>}
                    </Row>
                </div>
            </div>
        </>
    );
}

export default DailyLineups;