import {useState,useEffect,useMemo} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {useQuery} from 'react-query';
import {initProposal,getPublicTeam} from 'api/LeagueAPI';
import 'stylesheets/Trades.css';
import LoadingSpinner from 'components/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Rodal from 'rodal';


const no_player_selected = () => toast.error("⚠️ Please select at least one player to procede.");

const format_date = (date_str) => {
    var newDate = new Date(date_str);
    var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}`;
    return final_date_str;
}

const PlayerStats = props => {
    if (props.filter === '2020') {
        var stats = props.player.stats['2020'];
    } else if (props.filter === '2019') {
        var stats = props.player.stats['2019'];
    } else if (props.filter === '2021') {
        var stats = props.player.projections['2021'];
    } else if (props.filter === 'weekly') {
        var stats = props.player.projections.week;
    }
    return (
        <>
        {stats ?
        <>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{stats.goals}</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{stats.twoPointGoals}</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{stats.assists}</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{stats.groundBalls}</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{stats.turnovers}</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{stats.causedTurnovers}</td>
        <td style={{textAlign:'center',minWidth:'80px',borderBottom:'2px solid #E5E5E5'}}>{stats.savePct.toFixed(2)}</td>
        <td style={{textAlign:'center',minWidth:'90px',borderBottom:'2px solid #E5E5E5'}}>{stats.faceoffPct.toFixed(2)}</td>
        </>
        : 
        <>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>N/A</td>
        </>
        }
        </>
    );
}

const Player = props => {
    var player = props.data;
    const action = (player_id) => {
        if (props.arr.includes(player_id)) {
            props.setArr(props.arr.filter(player => player !== player_id))
        } else {
            props.setArr([...props.arr, player_id])
        }
        
    }
    return (
        <tr>
            <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{player.position}</td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}><span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={player.profileUrl} className="team-player-img" style={{height:'40px',borderRadius:'20px',marginRight:'3px'}} />{player.firstName} {player.lastName}</span></td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}><button className={`waiver-pick-up ${props.arr.includes(player.officialId) ? 'trade-selected' : ''}`} onClick={() => action(player.officialId)}>{props.arr.includes(player.officialId) ? 'Selected' : 'Select'}</button></td>
            <td/>
            <td style={{borderBottom:'2px solid #E5E5E5',borderRadius:'0px',textAlign:'center'}}>{player.next_game.opponent}</td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{format_date(player.next_game.start)}</td>
            <PlayerStats player={player} filter={props.statsFilter} />
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{player.rank}</td>
            <td style={{textAlign:'center',borderBottom:'2px solid #E5E5E5'}}>{(player.prevSeasonScore/player.prevSeasonGamesPlayed).toFixed(1)}</td>
        </tr>
    );
}

const StatsSort = props => {
    return (
        <select className="stats-select waiver-stats" style={{margin:'0px',marginRight:'5px'}} onChange={(e) => props.setStatsFilter(e.target.value)} value={props.statsFilter}>
            <option value="2020">2020 Stats</option>
            <option value="2019">2019 Stats</option>
            <option value="2021" disabled={!props.edge ? true : null}>2021 Proj. Stats</option>
            <option value="weekly" disabled={!props.edge ? true : null}>Weekly Proj. Stats</option>
        </select>
    );
}

const TeamSelect = props => {
    return (
        <select className="stats-select" style={{margin:'0px'}} onChange={(e) => {props.setCurrentTeam(e.target.value); props.setRequest([])}} value={props.currentTeam}>
            {Object.keys(props.teams).map((key,index) => (
                <option value={key}>{props.teams[key].name}</option>
            ))}
        </select>
    );
}

const TeamTable = props => {
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState();
    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
        }
        fetch(`/api/teams/${props.currentTeam}`, requestOptions).then(async response => {
			const resp = await response.json();
            console.log(resp)
            setData(resp);
        });
        setLoading(false);
    }, [props.currentTeam]);
    return (
        (loading ? <LoadingSpinner /> :
            <div style={{overflow:'scroll',width:'100%'}}>
            <table className="my-team-table" style={{minWidth:'900px'}}>
                <thead >
                    <tr >
                        <th colspan="4" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup' >Players</th>
                        <th colspan="2" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Next Game</th>
                        <th colspan="8" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Stats</th>
                        <th colspan="2" style={{borderBottom:'2px solid #E5E5E5'}} scope='colgroup'>Basics</th>
                    </tr>
                    <tr>
                        <th className="team-sub-header" scope='col'>SLOT</th>
                        <th className="team-sub-header" style={{minWidth:'200px'}} scope='col'>PLAYER</th>
                        <th className="team-sub-header" scope='col' />
                        <th className="team-sub-header" scope='col'/>
                        <th className="team-sub-header" scope='col'>OPP</th>
                        <th className="team-sub-header" scope='col'>TIME</th>
                        <th className="team-sub-header" scope='col'>G</th>
                        <th className="team-sub-header" scope='col'>2PT G</th>
                        <th className="team-sub-header" scope='col'>A</th>
                        <th className="team-sub-header" scope='col'>GB</th>
                        <th className="team-sub-header" scope='col'>T</th>
                        <th className="team-sub-header"  scope='col'>CT</th>
                        <th className="team-sub-header" scope='col'>Save %</th>
                        <th className="team-sub-header" scope='col'>Faceoff %</th>
                        <th className="team-sub-header" scope='col'>Rk</th>
                        <th className="team-sub-header" scope='col'>Pts/Game</th>
                    </tr>
                </thead>
                <tbody>
                    {data ? data.players.map((player) => (
                        <Player id={props.id} data={player} arr={props.request} setArr={props.setRequest} statsFilter={props.statsFilter} />
                    )) : <LoadingSpinner/>}
                </tbody>
            </table>
            </div>
        )
    );
}

const OtherTeams = props => {
    const { isLoading, error, data } = useQuery('proposal',() => initProposal(props.id),
        {onSuccess: data => {
            setCurrentTeam(Object.keys(data.teams)[0])
            props.setEdge(data.edge)
            props.setYourTeam(data.your_team)
        }
    });
    const [currentTeam,setCurrentTeam] = useState();
    const [statsFilter,setStatsFilter] = useState('2020');
    const next = () => {
        if (props.request.length !== 0) {
            props.setStage(1)
        } else {
            no_player_selected()
        }
    }
    return (
        <div>
            <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',justifyContent:'space-between',marginBottom:'20px'}}>
                <h3 style={{margin:'0px',fontWeight:'500'}}>Select Players You’d Like To Recieve</h3>
                {isLoading ? null :
                <div>
                    <StatsSort setStatsFilter={setStatsFilter} statsFilter={statsFilter} edge={data.edge}/>
                    <TeamSelect currentTeam={currentTeam} setCurrentTeam={setCurrentTeam} teams={data.teams} setRequest={props.setRequest} />
                </div>
                }
            </div>
            {isLoading ? null : <TeamTable currentTeam={currentTeam} request={props.request} setRequest={props.setRequest} statsFilter={statsFilter} />}
            <div style={{display:'flex',justifyContent:'flex-end'}}>
                <button className="trade-next" onClick={() => next()}>Next</button>
            </div>
        </div>
    );
}

const MyTeam = props => {
    const [statsFilter,setStatsFilter] = useState('2020');
    const next = () => {
        if (props.inReturn.length !== 0) {
            props.setFinalPropose(true);
        } else {
            no_player_selected()
        }
    }
    const back = () => {
        props.setStage(0);
    }
    return (
        <div>
            <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',justifyContent:'space-between',marginBottom:'20px'}}>
                <h3 style={{margin:'0px',fontWeight:'500'}}>Select Players You’d Like To Give</h3>
                <div>
                    <StatsSort setStatsFilter={setStatsFilter} statsFilter={statsFilter} edge={props.edge}/>
                </div>
            </div>
            <TeamTable currentTeam={props.your_team} request={props.inReturn} setRequest={props.setInReturn} statsFilter={statsFilter} />
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <button className="trade-next" onClick={() => back()}>Back</button>
                <button className="trade-propose" onClick={() => next()}>Propose</button>
            </div>
        </div>
    );
}

function ProposeTrade({match,location}) {
    const { params: { id } } = match;
    const [stage,setStage] = useState(0);
    const [request,setRequest] = useState([])
    const [inReturn,setInReturn] = useState([])
    const [yourTeam,setYourTeam] = useState();
    const [edge,setEdge] = useState();
    const [finalPropose, setFinalPropose] = useState(false);
    const [trading,setTrading] = useState(false);
    const propose = () => {
        setTrading(true);
        var trade = {
            request: request,
            inReturn: inReturn 
        }
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`,'content-type':'application/json'},
            method: 'POST',
            body: JSON.stringify(trade)
        }
        fetch(`/api/leagues/${id}/trades/propose`, requestOptions).then(async response => {
            if (response.ok) {
                const resp = await response.json();
                window.location.href = `/league/${id}/trades`;
            } else {
                setTrading(false);
            }
			
        });
    }
    return (
        <>
            <Nav league={true} />
            <LeagueNav current="trades" id={id}/>
            <div className="league-main">
                <h1 style={{margin:'0px'}}>Propose A Trade</h1>
                {stage === 0 ? <OtherTeams id={id} request={request} setRequest={setRequest} setStage={setStage} setEdge={setEdge} setYourTeam={setYourTeam} /> : <MyTeam your_team={yourTeam} id={id} edge={edge} inReturn={inReturn} setInReturn={setInReturn} setStage={setStage} setFinalPropose={setFinalPropose}/>}
            </div>
            <ToastContainer />
            <Rodal visible={finalPropose} onClose={() => setFinalPropose(false)} style={{borderRadius:'15px'}} height={400}>
                <h1>Hold Up ✋</h1>
                <h2 style={{fontWeight:'400'}}>Once a trade is accepted, you may experience lineup changes to ensure the trade goes through.</h2>
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'150px'}}>
                    <button className={`cancel-trade ${trading === true ? 'trading' : null}`} onClick={() => setFinalPropose(false)}>🛑 Cancel</button>
                    <button className={`go-through-trade ${trading === true ? 'trading' : null}`} onClick={() => propose()}>✅ I Understand</button>
                </div>
                {trading ? <LoadingSpinner /> : null}
            </Rodal>
        </>
    );
}

export default ProposeTrade;