import { useState, useEffect } from 'react';
import Nav from 'components/nav/Nav';
import DailyNav from 'components/daily/DailyNav.js';
import Cookies from 'js-cookie';
import { Row, Col } from 'react-simple-flex-grid';
import 'stylesheets/DailyLeaderboard.css';

function DailyLeaderboard(props) {
    const [leaderboard, setLeaderboard] = useState([]);
    const loadLeaderboard = async () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        const response = await fetch('/api/daily/leaderboard', requestOptions);
        const data = await response.json();
        setLeaderboard(data);
    }
    useEffect(() => {
        loadLeaderboard();
    }, []);
    const normalize_date = (date_str) => {
        var date = new Date(date_str);
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);
        var hours = newDate.getHours();
        var minutes = newDate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()}`;
        return final_date_str;  
    }
    const getOrdinalNum = (number) => {
    let selector;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
    };
    return (
        <>
            <Nav />
            <DailyNav />
            <div className="contests-header">
                <h1 style={{marginLeft:'45px'}}>Leaderboard</h1>
            </div>
            <div className="main">
            <Row>
                { leaderboard ? leaderboard.map((entry,index) => (                
                    <Col span={12} >
                    <div className="leaderboard-entry">
                        <div className="leaderboard-rank">
                            <h2>{getOrdinalNum(index+1)}</h2>
                        </div>
                        <div className="leaderboard-name">
                        {entry.user}
                        </div>
                        <div className={`leaderboard-points ${entry.total_points === 0 ? 'neutral' : (entry.total_points > 0 ? 'positive' : 'negative')}`} >
                        {entry.total_points} Pts
                        </div>
                        <div className="leaderboard-created">
                            Created on {normalize_date(entry.date_created)}
                        </div>
                        <a className="view-lineup" href={`/daily/lineups/${entry._id}`}>View Lineup</a>
                    </div>
                    </Col>
                )) : null}
            </Row>
            </div>
        </>
    );
}

export default DailyLeaderboard;