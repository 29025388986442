import {loadStripe} from '@stripe/stripe-js';
import Cookies from 'js-cookie';
import Nav from 'components/nav/Nav';
import prism_logo from 'static/images/Prism.svg';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/pages/BuyPrism.css';
import gives from 'static/icons/gives.svg';
import ReactTooltip from 'react-tooltip';

const stripePromise = loadStripe('pk_test_51I0sUHAkZVyee1Ecfxu0ItIs2TrMLoLs3IK6J2FG4O6QahAmQ4bczNf3ODxQLrzaHXsUKVkmzXqrx8i3ZpbLVI0q00tKcHADSL');

function BuyPrism(props) {
    const handleClick = async (event) => {
        // Get Stripe.js instance
        const stripe = await stripePromise;

        // Call your backend to create the Checkout Session
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
            method: 'POST'
        };
        const response = await fetch('/api/GDM/prism/create-checkout-session', requestOptions);

        const session = await response.json();

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }    
    }
    return (
        <>
            <Nav />
            <div className="main">
                <Row>
                    <Col xs={12} sm={{span:'8',offset:'2'}}>
                        <div>
                            <center>
                                <img className="prism-brand" src={prism_logo} />
                            </center>
                        </div>
                        <div>
                            <center>
                                <h1 className="headline">Access enriched stats, predictions, and insights.</h1>
                                <h2 className="bullet">Player Predictions</h2>
                                <h2 className="bullet">Team Preditcions</h2>
                                <h2 className="bullet">Trade Analysis</h2>
                                <h2 className="bullet">Lineup Optimization</h2>
                                <h2 className="bullet">Performance Graphs</h2>
                                <h1 className="cost" style={{marginBottom:'5px'}}><span className="price">$1.99</span> One Time Seasonal Purchase</h1>
                                <h2 >100% of our profits are donated through <span style={{fontFamily:'Recoleta'}}> <img style={{display:'inline',height:'20px',marginRight:'5px'}} src={gives} />Gives</span></h2>
                            </center>
                        </div>
                        <div>
                            <center>
                                <button role="link" className="form-btn buy-now" style={{opacity:'0.5'}} data-tip="Prism will launch with fantasy for the PLL">
                                    Buy Now
                                </button>
                                <ReactTooltip />
                            </center>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default BuyPrism;