import 'stylesheets/helpers/LoadingError.css';

function LoadingError(props) {
    return (
        <div className="error">
            {props.error}
        </div>
    );
}

export default LoadingError;