import { useState, useEffect } from 'react';
import 'stylesheets/DraftMessage.css';
import { initiateSocket, disconnectSocket, subscribeToChat, sendMessage, loadInitialChat, subscribeToPick } from 'helpers/DraftAPI.js';
import ScrollToBottom from 'react-scroll-to-bottom';

function ChatMessage(props) {
    const { message } = props;
    if (message.type === 'chat') {
        return <p>{message.name}: {message.message}</p>
    } else {
        return <p><i>{message.name} {message.message}</i></p>
    }
}

function DraftMessage(props) {
    const room = props.id;
    const [message, setMessage] = useState('');
    return (
        <div className="draft-message-wrapper">
            <ScrollToBottom className="draft-message">
                { props.chat.map((m) => 
                    <ChatMessage message={m} />
                )} 
            </ScrollToBottom>
            <div className="text-message">
                <input placeholder="Message..." className="message-input" type="text" name="name" value={message}
                    onChange={e => setMessage(e.target.value)} />
                <button className="send-text" onClick={() => {
                sendMessage(room, message);
                setMessage('');
                }}>Send</button>
            </div>
        </div>
    );
}

export default DraftMessage;