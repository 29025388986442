import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Logout from 'helpers/Logout';
import 'stylesheets/nav/AccountDropdown.css';
import share from 'static/icons/share.svg';
import settings from 'static/icons/settings.svg';
import logout from 'static/icons/logout.svg';

function AccountDropdown(props) {
    const [open,setOpen] = useState(false);
    const [profile,setProfile] = useState('');
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
          };
          fetch('/api/users/me', requestOptions)
            .then(response => response.json())
            .then(data => setProfile(`/api/rango/files/${data['profile_url']}`))
    }, []);
    return (
        <>
            <div className="account-dropdown" onClick={() => setOpen(!open)}>
                <div className="account-icon">
                    <img className="icon" id="icon" src={profile} />
                </div>
            </div>
            { open ?
            <div className="account-collapse">
                <div className="account-collaspe-wrapper">
                    <img className="account-collapse-icon" src={share} />
                    <a className="account-collapse-link" href="/share">Share</a>
                </div>
                <div className="account-collaspe-wrapper">
                    <img className="account-collapse-icon" src={settings} />
                    <a className="account-collapse-link" href="/settings">Settings</a>
                </div>
                <div className="account-collaspe-wrapper">
                    <img className="account-collapse-icon" src={logout} />
                    <a className="account-collapse-link" onClick={Logout}>Logout</a>
                </div>
            </div>
            : null}
        </>
    );
}

export default AccountDropdown;