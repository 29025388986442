import 'stylesheets/DraftCard.css';

function DraftCard(props) {
    return (
        <div className="draft-card" >
            <div className="team-image-wrapper">
                <img className="team-image" style={{height:'56px',borderRadius:'28px'}} src={`/api/rango/files/teamLogos/${props.logo}`} />
            </div>
            <div className="team-title">{props.name}</div>
        </div>
    )

}

export default DraftCard;