import {useQuery} from 'react-query';
import {results} from 'api/DraftAPI';

function Results(props) {
    const { isLoading, infoIsError, data, info_error,refetch } = useQuery('results', () => results(props.id));
    if (isLoading) return <center><h2>Loading Draft Results...</h2></center>;
    return (
        <table style={{width:'100%',border: '2px solid black',borderCollapse: 'collapse'}}>
            <thead>
                <tr>
                    <th style={{border: '2px solid black'}} colspan="1" scope='colgroup'>OVERALL PICK</th>
                    <th style={{border: '2px solid black'}} colspan="2" scope='colgroup'>PLAYER</th>
                    <th style={{border: '2px solid black'}} colspan="1" scope='colgroup'>TEAM</th>
                    <th style={{border: '2px solid black'}} colspan="1" scope='colgroup'>MANAGER</th>
                </tr>
                <tr>
                    <th style={{border: '2px solid black'}} scope='col'></th>
                    <th style={{border: '2px solid black'}} scope='col'>Pos</th>
                    <th style={{border: '2px solid black'}} scope='col'>Name</th>
                    <th style={{border: '2px solid black'}} scope='col'></th>
                    <th style={{border: '2px solid black'}} scope='col'></th>
                </tr>
            </thead>
            <tbody>
                {data.map((pick,index) => (
                    <tr>
                        <td style={{textAlign:'center'}}>{index+1}</td>
                        <td style={{textAlign:'center'}}>{pick.player.position}</td>
                        <td style={{textAlign:'center'}}><span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={pick.player.profileUrl} className="draft-player-img" style={{height:'40px',borderRadius:'20px',marginRight:'3px'}} />{pick.player.firstName} {pick.player.lastName}</span></td>
                        <td style={{textAlign:'center'}}>{pick.team.name}</td>
                        <td style={{textAlign:'center'}}>{pick.user.first_name} {pick.user.last_name}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default Results;