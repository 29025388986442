import {useState, useEffect} from 'react';
import Cookies, { set } from 'js-cookie';
import 'stylesheets/JoinContest.css';
import Countdown from 'react-countdown';
import {Event} from 'helpers/Tracking';
import { Row, Col } from 'react-simple-flex-grid';

const timeRender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Done</span>;
    } else {
      // Render a countdown
      if (seconds < 10){
        seconds = '0'+seconds;
      }
      if (minutes < 10){
        minutes = '0'+minutes;
      }
      if (hours < 10){
        hours = '0'+hours;
      }
      return <span className="daily-join-timer" style={{fontWeight:'600', fontSize:'30px', marginRight:'25px'}}>Live In {days}:{hours}:{minutes}:{seconds}</span>;
    }
};

function JoinContest(props) {
    const { contestId } = props;
    const [contest, setContest] = useState({});
    const [lineups, setLineups] = useState([]);
    const [lineup, setLineup] = useState({});
    const [length, setLength] = useState(0);
    const [search, setSearch] = useState('');
    const [currentEntrants, setCurrentEntrants] = useState('');
    useEffect(() => {
         const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch(`/api/daily/contests/${contestId}`, requestOptions).then(async response => {
            const data = await response.json();
            setContest(data);
            setLength(data['entrants'].length);
            setCurrentEntrants(data.entrants)

		 }).catch(() => {
            console.log('Error!')
        });
        fetch('/api/daily/lineups/me', requestOptions).then(async response => {
            const data = await response.json();
            setLineups(data);
            setLineup(data[0]._id);
		 }).catch(() => {
            console.log('Error!')
        })
    }, [contestId]);
    useEffect(() => {
        if (search.trim() === '') {
            setCurrentEntrants(contest.entrants)
        } else {
            setCurrentEntrants(contest.entrants.filter(name => name.startsWith(search)));
            
        }
    }, [search])
    const normalize_date = (date_str) => {
        var newDate = new Date(date_str);
        var hours = newDate.getHours();
        var minutes = newDate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()} ${strTime}`;
        return final_date_str;  

    }
    const join = () => {
        let data = new FormData();
        data.append('lineup', lineup);
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
            method: 'POST',
            body: data
        };
        fetch(`/api/daily/join/${contest._id}`, requestOptions).then(async response => {
            if (response.ok) {
                window.location.href = `/daily/contests?open=${contest._id}`
            }

		 }).catch(() => {
            console.log('Error!')
        });
    }
    return (
        (contest ?
        <>
        <div className="daily-join-header">
            <div>
            <h1 style={{marginTop:'-5px'}}>{contest.name}</h1>
            <p className="daily-join-num-entries">{length} {length === 1 ? 'Entry' : 'Entries'}</p>
            </div>
            <div>
                <Countdown date={contest.start} key="daily-timer" className="daily-join-timer" renderer={timeRender}/>
                <p className="daily-join-date">{normalize_date(contest.start)}</p>
            </div>
        </div>
        <hr />
        <Row gutter={40}>
            <Col sm={12} sm={8}>
                <div className="daily-join-summary-wrapper">
                    <p>Summary</p>
                    <div className="daily-join-summary">
                        {contest.summary}
                    </div>
                </div>
                <div className="daily-join-entrants-wrapper">
                    <p className="entrants-title">Entrants</p>
                    <input placeholder="Search" className="daily-join-entrants-search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    <div className="daily-join-entrants">
                        { currentEntrants ? currentEntrants.map((entrant => (
                            <div>{entrant}</div>
                        ))) : null}
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={4} >
                <div className="daily-join-lineups">
                    <p className="lineups-join-header">Lineups</p>
                    <div className="lineups-wrapper">
                    {lineups.map((current_lineup => (
                        <label class={`labl ${current_lineup._id === lineup ? 'selected-lineup' : ''}`}>
                            <input type="radio" name="radioname" value={current_lineup._id} onClick={() => {setLineup(current_lineup._id);console.log(current_lineup._id)}}/>
                            <div><h4>{current_lineup.date_created}</h4></div>
                        </label>
                    )))}
                    </div>
                    <center><button className="new-lineup" onClick={() => {window.location.href="/daily/newLineup?contest="+contestId;Event('DAILY','Create lineup from join','DAILY')}}>Create Lineup +</button></center>

                </div>
            </Col>
        </Row>
        <center><button className="join-contest-btn" onClick={() => {join();Event('DAILY','Join contest','DAILY')}}>Join Contest</button></center>
        </>
        : null)
    );
}

export default JoinContest;