import Nav from 'components/nav/Nav';
import share from 'static/images/share.gif';

const text = () => {
    window.location.href='sms://?&body=Join Join me in playing fantasy lacrosse league on Flow Fantasy. 🤙 https://flowfantasy.com/';
}
const email = () => {
    window.location.href='mailto:?subject=Join Me On Flow Fantasy&amp&body=Join me in playing fantasy lacrosse league on Flow Fantasy. 🤙 https://flowfantasy.com/';
}
function Share(props) {
    return (
        <>
            <Nav />
            <center>
                <h1 style={{fontSize:'40px'}}>Pass Flow Fantasy On</h1>
                <img style={{borderRadius:'5px'}} src={share} />
                <br />
                <button onClick={email} className="invite-btn email" style={{marginTop:'20px'}}>Email</button>
                <br />
                <button onClick={text} className="invite-btn text">Message</button>
            </center>
        </>
    );
}

export default Share;