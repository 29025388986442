import io from 'socket.io-client';
import Cookies from 'js-cookie';
let socket;
export const initiateSocket = (room) => {
  const STAGE = process.env.NODE_ENV;
  var SOCKET_URL;
  if (STAGE === 'development') {
    SOCKET_URL = "http://localhost/";
  } else {
    SOCKET_URL = "https://api.flowfantasy.com";
  }
  socket = io(SOCKET_URL, {query: `auth_token=${Cookies.get('token')}`});
  console.log(`Connecting socket...`);
  if (socket && room) socket.emit('joinDraft', room);
  console.log(`Joining room...`);
}

export const getDraft = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    };
    const data = await fetch(`/api/drafts/${id}/init`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const getDraftInfo = async (id) => {
    const requestOptions = {
        headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
    };
    const data = await fetch(`/api/drafts/${id}/info`,requestOptions).then(res =>
        res.json()
    )
    return data
}

export const pickPlayer = async (id,player_id) => {
  const requestOptions = {
    headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
  };
  const data = await fetch(`/api/drafts/${id}/pick/${player_id}`,requestOptions).then(res =>
    res.json()
  );
  return data
}

export const teams = async (id) => {
  const requestOptions = {
    headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
  };
  const data = await fetch(`/api/leagues/${id}/rosters`,requestOptions).then(res =>
    res.json()
  );
  return data
}

export const results = async (id) => {
  const requestOptions = {
    headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
  };
  const data = await fetch(`/api/drafts/${id}/results`,requestOptions).then(res =>
    res.json()
  );
  return data
}

export const autodraftPlayer = (id) => {
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch(`/api/drafts/${id}/autodraft`, requestOptions).then(async response => {
            if (!response.ok) {
                console.log('Error: An error was encountered while autodrafting');
            } else {
                const data = await response.json();
                console.log(data)
                return data.playerId; 
            }
        });
    }
