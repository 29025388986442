import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Countdown from 'react-countdown';
import 'stylesheets/DraftInfo.css';
import DraftCard from 'components/DraftCard';

const timeRender = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Done</span>;
    } else {
      // Render a countdown
      if (seconds < 10){
        seconds = '0'+seconds;
      }
      return <span>{minutes}:{seconds}</span>;
    }
};

const getDate = (dateStr) => {
    return new Date(dateStr)
}
function DraftInfo(props) {
    // const updateDraftInfo = () => {
    //     const requestOptions = {
    //         headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
    //     };
    //     fetch(`/api/draft/${props.id}/info`, requestOptions).then(async response => {
    //         const data = await response.json();
    //         if (data.hasOwnProperty('complete')) {
    //             window.location.reload()
    //         }
    //         const time = new Date(data.next_pick_expiry);
    //         var newDate = new Date(time.getTime() - time.getTimezoneOffset()*60*1000);
    //         if (newDate <= draftTime) {
    //             updateDraftInfo()
    //         }
    //         setDraftTimer(newDate);
    //         setRound(data.round);
    //         setPick(data.overall_pick);
    //         setPicks(data.picks);
    //         props.updatePicking(data.picking_now);
    //     });
    // }
    return (
        <div className="draft-details">
            <div className="draft-info">
                <div className="draft-timer">
                    <Countdown date={getDate(props.draftTime)} key={props.draftTime} onComplete={() => props.refetch()} renderer={timeRender}/>
                </div>
                <p className="time-remaining">TIME REMAINING</p>
                <p className="round-pick">Round {props.round} - Pick {props.pick+1}</p>
            </div>
            {props.picks ? 
                (props.picks.slice(props.pick).map((team,index) => (
                    <DraftCard name={team.name} logo={team.logo} key={index} />
                )))
            : null}
        </div>
    );
}

export default DraftInfo;