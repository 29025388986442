import { useState } from 'react';
import 'stylesheets/InviteModal.css';
import ReactTooltip from 'react-tooltip';

function InviteModal(props) {
    const [copied,setCopied] = useState(false);
    const STAGE = process.env.NODE_ENV;
    var BASE_URL;
    if (STAGE === 'development') {
        BASE_URL = "http://localhost:3000";
    } else {
        BASE_URL = "https://flowfantasy.com";
    }
    const text = () => {
        window.location.href='sms://?&body=Join '+props.leagueName+', my fantasy lacrosse league on Flow Fantasy. 🤙 '+BASE_URL+'/join/'+props.id;
    }
    const email = () => {
        window.location.href='mailto:?subject=Join Me On Flow Fantasy&amp&body=Join '+props.leagueName+', my fantasy lacrosse league on Flow Fantasy. 🤙 '+BASE_URL+'/join/'+props.id;
    }
    const copy = () => {
        const str = `${BASE_URL}/join/${props.id}`;
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(true);
    }
    return (
        <div className="invite-modal">
            <center>
            <h1 style={{marginTop:'0px'}} className="title">Share {props.leagueName}</h1>
            <p className="para">You've created your fantasy lacrosse league! Now invite friends to play against.</p>
            <div className="invites">
                <button className="invite-btn text" onClick={text}>Text Invite</button>
                <button className="invite-btn email" onClick={email}>Email Invite</button>
                <button className="invite-btn link" data-tip="Copied!" onClick={copy}>Copy Invite Link</button>
                { copied ? <ReactTooltip /> : null}
            </div>
            </center>
        </div>
    );
}

export default InviteModal;