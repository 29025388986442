import {useState, useEffect} from 'react';
import Nav from 'components/nav/Nav';
import DailyNav from 'components/daily/DailyNav'
import Cookies from 'js-cookie';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/DailyPlayers.css';

function DailyPlayers(props) {
    const [players,setPlayers] = useState([]);
    useEffect(() => {
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch('/api/daily/players/top', requestOptions).then(async response => {
            var data = await response.json();
            setPlayers(data);
        }).catch(() => {
            console.log('Error!')
        });
    }, []);
    const getOrdinalNum = (number) => {
        let selector;

        if (number <= 0) {
            selector = 4;
        } else if ((number > 3 && number < 21) || number % 10 > 3) {
            selector = 0;
        } else {
            selector = number % 10;
        }

        return number + ['th', 'st', 'nd', 'rd', ''][selector];
        };
    return (
        <>
            <Nav />
            <DailyNav />
            <div className="contests-header">
                <h1 style={{marginLeft:'45px'}}>Top Players</h1>
            </div>
            <div className="main"> 
                <Row>
                { players ? players.map((player,index) => (                
                    <Col span={12} >
                    <div className="leaderboard-entry">
                        <div className="leaderboard-rank">
                                <h2>{getOrdinalNum(index+1)}</h2>
                        </div>
                        <div></div>
                        <div className="leaderboard-player-name">{player.firstName} {player.lastName}</div>
                        <div className="leaderboard-player-team-logo-wrapper"><img className="leaderboard-player-team-logo" style={{height:'30px'}} src={player.team_logo} /></div>
                        <div className="leaderboard-player-cost">${player.cost}</div>
                    </div>
                    </Col>
                )) : null}
                </Row>
            </div>
        </>
    );
}

export default DailyPlayers;