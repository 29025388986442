import {useState, useEffect} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {initiateSocket, loadInitialChat, subscribeToChat, sendMessage} from 'api/ChatAPI';
import ScrollToBottom from 'react-scroll-to-bottom';

const FormatMessageDate = (dateStr) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date = new Date(dateStr);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return `${months[date.getMonth()]} ${date.getDate()} ${strTime}`
}


const Message = props => {
    return (
        <div style={{marginBottom:'20px',marginLeft:'20px'}}>
            <div style={{display:'flex'}}>
                <img style={{height:'40px',borderRadius:'20px'}} src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.data.team.logo}`} />
                <div style={{marginLeft:'10px'}}>
                    <div style={{alignSelf:'flex-start'}}><span style={{color:'#151617',fontWeight:'600',fontSize:'14px'}}>{props.data.name}</span> <span style={{fontSize:'12px',color:'#151617'}}>({props.data.team.name})</span> <span style={{color:'#a5a6a7',fontSize:'11px'}}>• {FormatMessageDate(props.data.timestamp)}</span></div>
                    <div style={{alignSelf:'flex-end',justifySelf:'flex-start',color:'#6c6d6f'}}>{props.data.message}</div>
                </div>
            </div>

        </div>
    );
}

function Chat({match,location}) {
    const { params: { id } } = match;
    const [message,setMessage] = useState('');
    const [chat,setChat] = useState([]);
    const [teams,setTeams] = useState({})
    useEffect(() => {
        initiateSocket(id);
        loadInitialChat((err, data) => {
            if(err) return;
            var final_teams = {};
            data.teams.map((team) => (
                final_teams[team.user] = {'name':team.name,'logo':team.logo}
            ));
            data.messages.map((message) => (
                message['team'] = final_teams[message['user']]
            ));
            setChat(data.messages);
        });
        subscribeToChat((err, data) => {
            if(err) return;
            data.message.team = {'name':data.team.name,'logo':data.team.logo}
            setChat(oldChats =>[...oldChats, data.message])
        });
    }, []);
    return (
        <>
            <Nav league={true}/>
            <LeagueNav current="chat" id={id} />
            <div className="league-main">
                <div>
                    <ScrollToBottom className="full-league-chat">
                        {chat ? chat.map((message) => (<Message data={message}/>)) : null}
                    </ScrollToBottom>
                    <div className="league-chat-form" style={{width:'80%',maxWidth:'800px'}}>
                        <input type="text" placeholder="Talk Smalk" value={message} onChange={(e) => setMessage(e.target.value)} style={{border:'1px solid rgb(165, 166, 167)',height:'35px',marginBottom:'10px',marginLeft:'10px',paddingLeft:'8px',marginRight:'10px',width:'90%'}} />
                        <button className={`league-chat-send ${message.length > 0 ? 'active' : ''}`} onClick={() => {sendMessage(id,message);setMessage('')}}>Send</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Chat;