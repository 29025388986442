import {useEffect} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {getNotiifcations} from 'api/LeagueAPI';
import {useQuery} from 'react-query';
import LoadingSpinner from 'components/LeagueLoadingSpinner';
import { Row, Col } from 'react-simple-flex-grid';
import 'stylesheets/Notifications.css';
import Cookies from 'js-cookie';

const FormatMessageDate = (dateStr) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date = new Date(dateStr);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return `${months[date.getMonth()]} ${date.getDate()} ${strTime}`
}

const Notification = props => {
    useEffect(() => {
        if (!props.data.read) {
            const requestOptions = {
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
            };
            fetch(`/api/notifications/${props.data._id}/read`, requestOptions).then(async response => {
                const data = await response.json();
            });
        }

    }, []);
    return (
        <div className={`notification ${!props.data.read ? 'unread' : ''}`}>
            <div style={{display:'flex'}}>
                <img style={{height:'40px',borderRadius:'20px'}} src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.data.team.logo}`} />
                <div style={{marginLeft:'10px'}}>
                    <div style={{alignSelf:'flex-start'}}><span style={{color:'#151617',fontWeight:'600',fontSize:'14px'}}>{props.data.originator.first_name} {props.data.originator.last_name}</span> <span style={{fontSize:'12px',color:'#151617'}}>({props.data.team.name})</span> <span style={{color:'#a5a6a7',fontSize:'11px'}}>• {FormatMessageDate(props.data.created_at)}</span></div>
                    <div style={{alignSelf:'flex-end',justifySelf:'flex-start',color:'#6c6d6f'}}>{props.data.type} - {props.data.details}</div>
                </div>
            </div>
            
        </div>
    );
}


function Notifications({location, match}) {
    const { params: { id } } = match;
    const { isLoading, error, data } = useQuery('notifications',() => getNotiifcations(id));
    return (
        <>
            <Nav league={true} />
            <LeagueNav current="notifications" id={id}/>
            <div className="league-main">
                <h1>Notifications</h1>
                {isLoading ? <LoadingSpinner /> : 
                <Row>
                <div className="notifications">
                        {data.map((notification) => (
                            <Col span={12}>
                                <Notification data={notification} />
                            </Col>
                        ))}
                </div>
                </Row>
                }  
            </div>
        </>
    );
}

export default Notifications;