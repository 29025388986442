import { useState, useEffect } from 'react';
import 'stylesheets/pages/Settings.css';
import Nav from 'components/nav/Nav';
import Cookies from 'js-cookie';
import saved_icon from 'static/icons/saved.svg';
import not_saved_icon from 'static/icons/not_saved.svg';
import ClickNHold from 'react-click-n-hold';
import Logout from 'helpers/Logout';

function Settings(props) {
    const [saved, setSaved] = useState(true);
    const [icon,setIcon] = useState('');
    const [services,setServices] = useState([]);
    const icons_ids = {'default_helmet.svg':'black','green_helmet.svg':'green','light_blue_helmet.svg':'light_blue','purple_helmet.svg':'purple','brown_helmet.svg':'brown','teel_helmet.svg':'teel','red_helmet.svg':'red'};
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch('/api/users/me', requestOptions)
        .then( async response => {
            const user = await response.json();
            setIcon(user['profile_url']);
            setServices(user['services']);
        });
    }, []);
    useEffect(() => {
        if (icon !== '') {
            setSaved(false);
            const id = icons_ids[icon];
            document.getElementById(id).checked = true;
            let update = new FormData();
            update.append('profile_url', icon);
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
                body: update
            };
            fetch('/api/users/me', requestOptions)
            .then( async response => {
                if (!response.ok) {
                    setSaved(false);
                } else {
                    setSaved(true);
                }
            });
        }
    }, [icon])
    const removeService = (service) => {
        setSaved(false);
        let remove = new FormData();
        remove.append('service',service);
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
            body: remove
        }
        fetch('/api/users/me', requestOptions)
        .then( async response => {
            if (!response.ok) {
                setSaved(false);
            } else {
                document.getElementById(service).remove()
                setSaved(true);
            }
        })
    }
    const deleteAccount = () => {
        const requestOptions = {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        }
        fetch('/api/users/me/delete',requestOptions)
        .then( async response => {
            if (response.ok) {
                Logout()
            }
        });
    }
    return (
        <>
            <Nav />
            <div className="main">
                <div className="header">
                    <h1>Settings</h1>
                    <div className="saved-status">
                        <img className="saved-icon" src={saved ? saved_icon : not_saved_icon} />
                        <span className={`saved-text ${saved ? "true" : "not" }`}>Settings Saved</span>
                    </div>
                </div>
                <hr />
                <div className="personalization" style={{marginBottom:'20px'}}>
                    <h2 className="setting-section-title">Personalization Settings</h2>
                    <center>
                        <p className="icon-title">Icon Color</p>
                        <div className="color-picker" onChange={(e) => setIcon(e.target.value)}>
                            <input type="radio" name="color" id="black" value="default_helmet.svg" />
                            <label for="black"><span class="black"></span></label>

                            <input type="radio" name="color" id="green" value="green_helmet.svg" />
                            <label for="green"><span class="green"></span></label>

                            <input type="radio" name="color" id="light_blue" value="light_blue_helmet.svg" />
                            <label for="light_blue"><span class="light_blue"></span></label>

                            <input type="radio" name="color" id="purple" value="purple_helmet.svg" />
                            <label for="purple"><span class="purple"></span></label>

                            <input type="radio" name="color" id="brown" value="brown_helmet.svg" />
                            <label for="brown"><span class="brown"></span></label>

                            <input type="radio" name="color" id="teel" value="teel_helmet.svg" />
                            <label for="teel"><span class="teel"></span></label>

                            <input type="radio" name="color" id="red" value="red_helmet.svg" />
                            <label for="red"><span class="red"></span></label>
                        </div>
                    </center>
                </div>
                <hr />
                <div className="services">
                    <h2 className="setting-section-title">Services</h2>
                    {services.length ? 
                        services.map((service) => (
                            <div className="service" id={service}>
                                <p className="service-name">{service.trim().replace(/^\w/, (c) => c.toUpperCase())}</p>
                                <button className="remove-service" onClick={() => removeService(service)}>Remove</button>
                            </div>
                        )) : <p>You have no services at the moment.</p> }
                    <p>* Removing a service cannot be undone</p>
                </div>
                <hr />
                <div className="jargon">
                    <h2 className="setting-section-title">Jargon</h2>
                    <div style={{marginBottom:'10px'}}>
                        <a className="jargon-item" href="/tos">View Our Terms Of Service</a>
                    </div>
                    <div>
                        <a className="jargon-item" href="/privacypolicy">View Our Privacy Policy</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;