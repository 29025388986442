import { useState } from 'react';
import 'stylesheets/forms/AuthForm.css';
import Cookies from 'js-cookie';

function LoginForm(props) {
    const [error, setError] = useState('');
    const [emailError,setEmailError] = useState('');
    const [passwordError,setPasswordError] = useState('');
    const[firstName,setFirstName] = useState('');
    const[lastName,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [valid,setValid] = useState(true);
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regPass = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const validateEmail = e => {
        const value = e.target.value.trim().toLowerCase();
        if(!regEmail.test(value)){
            setEmailError('Please enter a valid email.');
            setValid(false);
            document.getElementById('email').style.borderColor = '#D82334';
        }
        else {
            setEmailError('');
            setValid(true);
            document.getElementById('email').style.borderColor = '#E0E0E0';
        }
    }
    const validatePassword = e => {
        const value = e.target.value.trim();
        if(!regPass.test(value)){
            setValid(false);
            setPasswordError("Password must have 8 characters, a letter, a number, and a special character.");
            document.getElementById('password').style.borderColor = '#D82334';
        }
        else {
            setValid(true);
            setPasswordError('');
            document.getElementById('password').style.borderColor = '#E0E0E0';
        }
    }
    const validateName = e => {
        const value = e.target.value.trim();
        if (value === '') {
            setValid(false);
            e.target.style.borderColor = '#D82334';
        } else {
            setValid(true);
            e.target.style.borderColor = '#E0E0E0';
        }
    }
    const handleSubmit = e => {
        e.preventDefault();
        if (valid === true & email !== '' & password !== '' & firstName !== '' & lastName !== '') {
            let data = new FormData();
            data.append('first_name', firstName);
            data.append('last_name', lastName);
            data.append('email', email);
            data.append('password', password);
            fetch('/api/auth/register', {
                method: 'POST',
                body: data
            }).then(async response => {
                const resp_data = await response.json();
                if (!response.ok) {
                    setError(resp_data['message']);
                } else {
                    Cookies.set('token',resp_data['token']);
                    if (props.from) {
                        window.location.href = props.from;
                    } else {
                        window.location.href = '/leagues';
                    }
                }
            });
        }
    }
    return (
        <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input className="form-input" placeholder="First Name" id="first" type="text" onBlur={validateName} value={firstName} onChange={(e) => setFirstName(e.target.value.trim())} />
                </div>
                <div className="input-wrapper">
                    <input className="form-input" placeholder="Last Name" id="last" type="text" onBlur={validateName} value={lastName} onChange={(e) => setLastName(e.target.value.trim())} />
                </div>
                <div className="input-wrapper" style={{marginTop:'35px'}}>
                    <input className="form-input" placeholder="Email" id="email" type="email" onBlur={validateEmail} value={email} onChange={(e) => setEmail(e.target.value.trim().toLocaleLowerCase())} />
                    <span className="input-error" id="error">{emailError}</span>
                </div>
                <div className="input-wrapper">
                    <input className="form-input" placeholder="Password" id="password" type="password" onBlur={validatePassword} value={password} onChange={(e) => setPassword(e.target.value.trim())} />
                    <span className="input-error" id="error">{passwordError}</span>
                </div>
                <div className="form-error">{error}</div>
                <center><input type="submit" className="form-btn signup-submit" value="Sign Up" /></center>
            </form>
        </div>
    );
}

export default LoginForm;