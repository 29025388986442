import { useState } from 'react';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/forms/AuthForm.css';
import PreNav from 'components/nav/PreNav';
import Cookies from 'js-cookie';

function Reset({match, location}) {
    if (Cookies.get('token')) {
        window.location.href = '/leagues'
    }
    const { params: { resetToken } } = match;
    const [password,setPassword] = useState('');
    const [passwordError,setPasswordError] = useState('');
    const [error,setError] = useState('');
    const [valid,setValid] = useState(true);
    const regPass = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const validatePassword = e => {
        const value = e.target.value.trim();
        if(!regPass.test(value)){
            setValid(false);
            setPasswordError("Password must have 8 characters, a letter, a number, and a special character.");
            e.target.style.borderColor = '#D82334';
        }
        else {
            setValid(true);
            setPasswordError('');
            e.target.style.borderColor = '#E0E0E0';
        }
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (valid === true & password !== '') {
            let data = new FormData();
            data.append('password', document.getElementById('password').value);
            data.append('reset_token', document.getElementById('reset_token').value)
            fetch('/api/auth/reset', {
                method: 'POST',
                body: data
            }).then(async response => {
                const data = await response.json();
        
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response statusText
                    const error = (data && data.message) || response.statusText;
                    setError(error);
                } else {
                    setError('');
                    window.location.href = '/login';
                }
        
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
        }
    }
    return (
        <>
            <PreNav/>
            <Row style={{paddingLeft:'100px',paddingRight:'100px'}}>
                <Col xs={12} sm={{span: 4,offset:4}} >
                    <h1>Reset Password</h1>
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit}>
                            <div className="input-wrapper">
                                <input className="form-input" placeholder="New Password" id="password" type="password" onBlur={validatePassword} value={password} onChange={(e) => setPassword(e.target.value.trim())} />
                                <span className="error" id="error">{passwordError}</span>
                            </div>
                            <div className="form-error">{error}</div>
                            <input type="hidden" id="reset_token" value={resetToken} />
                            <input type="submit" className="form-btn reset-email-submit" value="Reset Password" />
                        </form>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Reset;