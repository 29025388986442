import {useState} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {getPlayer} from 'api/LeagueAPI';
import {useQuery} from 'react-query';
import { Row, Col } from 'react-simple-flex-grid';
import LoadingSpinner from 'components/LeagueLoadingSpinner';
import 'stylesheets/Player.css';

const PlayerStats = props => {
    if (props.filter === '2020') {
        var stats = props.player.stats['2020'];
    } else if (props.filter === '2019') {
        var stats = props.player.stats['2019'];
    } else if (props.filter === '2021') {
        var stats = props.player.projections['2021'];
    } else if (props.filter === 'weekly') {
        var stats = props.player.projections.week;
    }
    return (
        (stats ?
        <>
        <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'wrap',backgroundColor:'#e5e5e5',height:'100%',borderRadius:'10px',marginBottom:'20px'}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.goals}</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>Goals</h3>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.twoPointGoals}</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>2Pt Goals</h3>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.assists}</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>Assists</h3>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.turnovers}</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>Turnovers</h3>
            </div>
        </div>
        <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'wrap',backgroundColor:'#e5e5e5',height:'100%',borderRadius:'10px',marginBottom:'20px'}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.groundBalls}</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>GBs</h3>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.causedTurnovers}</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>CT</h3>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.shots}</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>Shots</h3>
            </div>
        </div>
        <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'wrap',backgroundColor:'#e5e5e5',height:'100%',borderRadius:'10px',marginBottom:'20px'}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.faceoffPct.toFixed(2)*100} %</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>Faceoff %</h3>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>{stats.savePct.toFixed(2)*100} %</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>Save %</h3>
            </div>
        </div>
        </>
        :
        <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'wrap'}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                <h2 style={{margin:'0px',fontSize:'30px'}}>N/A</h2>
                <h3 style={{margin:'0px',fontWeight:'400'}}>Goals</h3>
            </div>
        </div>
        )
    );
}

function Player({match,location}) {
    const { params: { playerid } } = match;
    const { isLoading, error, data } = useQuery('player',() => getPlayer(playerid));
    const [statsYear,setStatsYear] = useState('2020');
    return (
        <>
            <Nav league={true} />
            {isLoading ? <LoadingSpinner /> :
            <div style={{margin:'20px'}}>
                <Row>
                    <Col xs={{span:12}} sm={{span:6,offset:3}}><center><h1>{data.player.firstName} {data.player.lastName}</h1></center></Col>
                </Row>
                <Row>
                    <Col xs={{span:12}} sm={{span:6,offset:3}}>
                        <center><img src={data.player.profileUrl} style={{height:'130px',borderRadius:'65px'}}/></center>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span:12}} sm={{span:6,offset:3}}>
                        <center><h2 style={{fontWeight:'500'}}>{data.player.position} | {data.player.jerseyNum}</h2><h2>{data.player.teamId}</h2></center>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span:12}} sm={{span:12}} style={{backgroundColor:'#e5e5e5',height:'100%',borderRadius:'10px'}}>
                        <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'wrap'}}>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px',marginBottom:'20px'}}>
                                <h2 style={{margin:'0px',fontSize:'30px'}}>{data.player.height}</h2>
                                <h3 style={{margin:'0px',fontWeight:'400'}}>Height</h3>
                            </div>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px'}}>
                                <h2 style={{margin:'0px',fontSize:'30px'}}>{data.player.weight}</h2>
                                <h3 style={{margin:'0px',fontWeight:'400'}}>Weight</h3>
                            </div>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px'}}>
                                <h2 style={{margin:'0px',fontSize:'30px'}}>{data.player.age}</h2>
                                <h3 style={{margin:'0px',fontWeight:'400'}}>Age</h3>
                            </div>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px'}}>
                                <h2 style={{margin:'0px',fontSize:'30px'}}>{data.player.college}</h2>
                                <h3 style={{margin:'0px',fontWeight:'400'}}>College</h3>
                            </div>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'20px'}}>
                                <h2 style={{margin:'0px',fontSize:'30px'}}>{data.player.hometown}</h2>
                                <h3 style={{margin:'0px',fontWeight:'400'}}>Hometown</h3>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <select className="stats-select" style={{marginTop:'20px',marginLeft:'0px',marginBottom:'20px'}} onChange={(e) => setStatsYear(e.target.value)}>
                        <option value="2020">2020 Stats</option>
                        <option value="2019">2019 Stats</option>
                        <option value="2021" disabled={!data.edge ? true : null}>2021 Proj. Stats</option>
                        <option value="weekly" disabled={!data.edge ? true : null}>Weekly Proj. Stats</option>
                    </select>
                </Row>
                <Row>
                    <Col xs={{span:12}} sm={{span:12}}>
                        <PlayerStats player={data.player} filter={statsYear}/>
                    </Col>
                </Row>
            </div>
            }
        </>
    );
}

export default Player;