import {useState} from 'react';
import {leagueStateListener} from 'api/LeagueAPI';
import {useQuery} from 'react-query';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import 'stylesheets/LeagueManagers.css';
import {getLeagueMangers,deleteTeam} from 'api/LeagueAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import { Row, Col } from 'react-simple-flex-grid';
import InviteModal from 'components/InviteModal';
import Rodal from 'rodal';
import ReactTooltip from 'react-tooltip';

function Managers({match, location}) {
    const { params: { id } } = match;
    const [inviteOpen, setInviteOpen] = useState(false);
    var previousState = 'predraft';
    const check = useQuery('leagueState',() => leagueStateListener(id),{refetchInterval:5000})
    const { isLoading, error, data } = useQuery('leagueManagers',() => getLeagueMangers(id));
    const removeTeam = (team_id) => {
        if (data.admin === true) {
            deleteTeam(team_id)
        }
    }
    if (isLoading) return <LoadingSpinner/>;
    return (
        <>
        <Nav league={true}/>
        <LeagueNav id={id}/>
        <div className="league-main">
            <div className="league-managers-header">
                <h1>Managers</h1>
                <button className={`league-managers-invite ${data.league.draftHeld === true ? 'draft-held' : ''}`} onClick={() => setInviteOpen(true)}>Invite</button>
            </div>
            <Row>
                <Col span={12}>
                    <table style={{width:'100%',borderCollapse:'collapse',borderStyle:'hidden'}}>
                        <thead>
                            <tr>
                                <th>Team</th>
                                <th>Manger</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody style={{width:'100%'}}>
                            {data.teams.map((team) => (
                                <tr className="manager-table-th">
                                    <td className="manager-table-td" style={{justifyContent:'center',textAlign:'center'}}><button className="manager-team-btn"><div style={{display:'flex',alignItems:'center'}}><img height="40" style={{borderRadius:'20px',marginRight:'10px'}} src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${team.team_logo}`}/><span>{team.team_name}</span></div></button></td>
                                    <td className="manager-table-td" style={{textAlign:'center'}}>{team.manager}</td>
                                    <td className="manager-table-td" style={{textAlign:'center'}}><button className={`remove-team ${data.admin === true ? 'admin' : ''}`} data-tip="Once a team is removed, it can't be undone." onClick={() => removeTeam(team._id)}>Remove</button></td>
                                    {data.admin === true ? <ReactTooltip place="top"/> : null}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </div>
        <Rodal visible={inviteOpen} onClose={() => setInviteOpen(false)} height={400}>
            <InviteModal id={id}/>
        </Rodal>
        </>
    );
}

export default Managers;