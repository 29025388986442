import {useState} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import { Row, Col } from 'react-simple-flex-grid';
import LoadingSpinner from 'components/LoadingSpinner';
import {getPublicTeam} from 'api/LeagueAPI';
import {useQuery} from 'react-query';

const TeamPlayer = props => {
    return (
        <a href={`/player/${props.player.officialId}`} style={{color:'#000'}}>
            <div style={{height:'70px',backgroundColor:'#e5e5e5',marginBottom:'20px',display:'flex',alignItems:'center',borderRadius:'15px'}}>
                <img src={props.player.profileUrl} style={{height:'50px',borderRadius:'10px',marginLeft:'10px'}}/>
                <div style={{marginLeft:'10px',fontSize:'20px',fontWeight:'400'}}>{props.player.firstName} {props.player.lastName} | {props.player.position}</div>
            </div>
        </a>
    );
}

function LeagueTeam({match,location}) {
    const { params: { team_id } } = match;
    const { isLoading, error, data } = useQuery('team',() => getPublicTeam(team_id));
    return (
        <>
            {isLoading ? <LoadingSpinner /> :
                <>
                <Nav league={true} />
                <LeagueNav id={data.team.league}/>
                <div className="league-main">
                    <Row>
                        <Col xs={{span:12}} sm={{span:6,offset:3}}><center><h1>{data.team.name}</h1></center></Col>
                    </Row>
                    <Row>
                        <Col xs={{span:12}} sm={{span:6,offset:3}}>
                            <center><img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${data.team.logo}`} style={{height:'120px',borderRadius:'60px'}}/></center>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span:12}} sm={{span:6,offset:3}}>
                            <center><h2 style={{fontWeight:'500', margin:'0px',marginTop:'10px'}}>{data.team.manager.first_name} {data.team.manager.last_name}</h2><h2 style={{margin:'0px'}}>{data.team.wins} - {data.team.losses}</h2></center>
                        </Col>
                    </Row>
                    <Row>
                        <h2>Players</h2>
                    </Row>
                    <Row gutter={20}>
                        {data.players.map((player) => (
                            <Col xs={{span:12}} sm={{span:4}}>
                                <TeamPlayer player={player}/>
                            </Col>
                        ))}
                    </Row>
                </div>
                </>
            }
        </>
    );
}

export default LeagueTeam;