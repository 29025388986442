import { useState, useEffect } from 'react';
import 'stylesheets/nav/Nav.css';
import logo from 'static/images/logo.png';
import AccountDropdown from 'components/nav/AccountDropdown';
import opened from 'static/icons/opened_menu.svg';
import closed from 'static/icons/closed_menu.svg';
import 'stylesheets/nav/AccountDropdown.css';
import share from 'static/icons/share.svg';
import settings from 'static/icons/settings.svg';
import logout from 'static/icons/logout.svg';
import Logout from 'helpers/Logout';
import daily_logo from 'static/icons/daily.svg';
import prism_logo from 'static/icons/prism.svg';
import {PageView, initGA, Event} from 'helpers/Tracking';

function Nav(props) {
    const [collapse,setCollaspe] = useState(false);
    useEffect(() => {
        initGA('UA-161394853-1');
    }, []);
    return (
        <div className="navbar">
            {props.league !== true ?
            <a href="/leagues" className="navbar-brand-link">
            <div className="navbar-brand">
                <img src={logo} className="navbar-logo" />
                <h1 className="navbar-brand-name">Flow Fantasy</h1>
            </div>
            </a>
            : <div><img src={logo} className="navbar-logo" /></div>}
            <div className="navbar-items">
                <div className="navbar-collapse">
                    <a className="navbar-link" href="/leagues" onClick={() => Event("NAV","Leagues","NAV")}>Leagues</a>
                    <a className="navbar-link daily" href="/daily" onClick={() => Event("NAV","Daily","NAV")}><img className="daily-product-logo" src={daily_logo} />Daily</a>
                    {/* <a className="navbar-link prism" href="/prism" onClick={() => Event("NAV","Prism","NAV")}><img className="prism-product-logo" src={prism_logo} />Prism</a> */}
                </div>
                <div className="navbar-account">
                    <AccountDropdown />
                </div>
            </div>
            <div className="collapse-control">
                <button onClick={() => setCollaspe(!collapse)} className="collapse-btn"><img src={collapse ? opened : closed} className="collapse-icon" /></button>
            </div>
            <div className={`navbar-collapsed-card ${collapse ? 'open' : null}`}>
                <center>
                <div className="collaspe-nav-items">
                    <a className="navbar-link-collapse" href="/leagues" onClick={() => Event("NAV","Leagues","NAV")}>Leagues</a>
                    <a className="navbar-link-collapse daily" href="/daily" onClick={() => Event("NAV","Daily","NAV")}><img className="daily-product-logo" src={daily_logo} />Daily</a>
                    {/* <a className="navbar-link-collapse prism" href="/prism" onClick={() => Event("NAV","Prism","NAV")}><img className="prism-product-logo" src={prism_logo} />Prism</a> */}
                </div>
                </center>
                <hr style={{marginTop:'30px'}}/>
                <div className="collapse-account">
                    <div className="account-collaspe-wrapper">
                        <img className="account-collapse-icon" src={share} />
                        <a className="account-collapse-link" href="/share" onClick={() => Event("NAV","Share","NAV")}>Share</a>
                    </div>
                    <div className="account-collaspe-wrapper">
                        <img className="account-collapse-icon" src={settings} />
                        <a className="account-collapse-link" href="/settings" onClick={() => Event("NAV","Settings","NAV")}>Settings</a>
                    </div>
                    <div className="account-collaspe-wrapper">
                        <img className="account-collapse-icon" src={logout} />
                        <a className="account-collapse-link" onClick={() => {Logout();Event("NAV","Logout","NAV")}}>Logout</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nav;