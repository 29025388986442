import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {useQuery} from 'react-query';
import {getTeams} from 'api/LeagueAPI';
import { Row, Col } from 'react-simple-flex-grid';
import LoadingSpinner from 'components/LeagueLoadingSpinner';

const TeamCard = props => {
    return (
        <a href={`/teams/${props.team._id}`} style={{color:'#000'}}>
            <div style={{backgroundColor:'#e5e5e5',height:'90px',borderRadius:'15px',padding:'10px'}}>
                <div style={{display:'flex',alignItems:'start'}}>
                    <img style={{height:'50px',borderRadius:'25px'}} src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.team.logo}`} />
                    <h2 style={{marginTop:'2px',marginLeft:'10px'}}>{props.team.name}</h2>
                </div>
                <div style={{marginLeft:'60px',marginTop:'-20px'}}>{props.team.manager.first_name} {props.team.manager.last_name}</div>
            </div>
        </a>
    );
}

function Teams({match,location}) {
    const { params: { id } } = match;
    const { isLoading, error, data } = useQuery('league_teams',() => getTeams(id));
    return (
        <>
            <Nav league={true} />
            <LeagueNav id={id}/>
            <div className="league-main">
                <h1>Teams</h1>
                
                {isLoading ? <LoadingSpinner /> :
                    <Row gutter={40}>
                        {data.map((team) => (
                            <Col xs={{span:12}} sm={{span:4}} style={{marginBottom:'20px'}}>
                                <TeamCard team={team}/>
                            </Col>
                        ))}
                    </Row>
                }
            </div>
        </>
    );
}

export default Teams;