import {useState, useEffect} from 'react';
import { Row, Col } from 'react-simple-flex-grid';
import Cookies from 'js-cookie';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/DailyLobby.css';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import JoinContest from 'components/daily/JoinContest';
import JoinCode from 'components/daily/JoinCode';
import {PageView, initGA, Event} from 'helpers/Tracking';
import carrot from 'static/icons/carrot.svg';

function DailyLobby(props) {
    const [lobby, setLobby] = useState([]);
    const [joinOpen, setJoinOpen] = useState(false);
    const [codeOpen, setCodeOpen] = useState(false);
    const [joining, setJoining] = useState();
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const loadLobby = async () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        const response = await fetch('/api/daily/lobby', requestOptions);
        const data = await response.json();
        console.log(data)
        setLobby(data);
    }
    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');

        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    }
    useEffect(() => {
        loadLobby();
        var query = getQueryParams(document.location.search);
        if (query['open']) {
            setJoining(query['open']);
            setJoinOpen(true);
        }
        PageView();
    }, []);
    const normalize_date = (date_str) => {
        var newDate = new Date(date_str);
        var hours = newDate.getHours();
        var minutes = newDate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()} ${strTime}`;
        return final_date_str;  

    }
    const join_contest = (contestId) => {
        setJoining(contestId)
        setJoinOpen(true);
    }
    return (
        <>
        <Row>
            <Col span={12} >
                <div className="lobby">
                    <div className="lobby-top-bar">
                        <a style={{color:'#000'}} href="/daily/create" onClick={() => Event("DAILY",'Create Contest',"DAILY")}><span className="plus">+</span> Create a Contest</a>•
                        <a style={{color:'#000'}} href="/daily/newLineup" onClick={() => Event("DAILY",'Create Lineup',"DAILY")}><span className="plus">+</span> Create a Lineup</a><span className="final-top-bar-dot">•</span>
                        <span onClick={() => setSubMenuOpen(!subMenuOpen)}><img src={carrot} style={{height:'12px',marginTop:'6px',marginLeft:'10px'}} className="lobby-carrot" /></span>
                        <div className={`lobby-top-bar-collapse ${subMenuOpen ? 'top-open' : ''}`} >
                            <a style={{color:'#000'}} onClick={() => {Event("DAILY",'Join By Code',"DAILY");setCodeOpen(true)}}>Join By Code</a><span className="final-top-bar-dot">•</span>
                            <a style={{color:'#000'}} href="https://help.flowfantasy.com/scoring.html" target="_blank" onClick={() => {Event("DAILY",'Scoring',"DAILY")}}>Scoring</a><span className="final-top-bar-dot">•</span>
                            <a style={{color:'#000'}} href="/daily/leaderboard" onClick={() => Event("DAILY",'Leaderboard',"DAILY")}>Leaderboard</a><span className="final-top-bar-dot">•</span>
                            <a style={{color:'#000'}} href="/daily/players" onClick={() => Event("DAILY",'Players',"DAILY")}>Players</a>
                        </div>
                    </div>
                </div>
                <div className="lobby-table-wrapper">
                <table className="lobby-table">
                    <thead>
                        <tr>
                            <th>Contest</th>
                            <th>Entries</th>
                            <th>Start</th>
                            <th>End</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {lobby.map((contest) => (
                        <tr>
                            <td>{contest.hot ? '🔥' : null} {contest.prize ? '💰' : null} {contest.name}</td>
                            <td>{contest.entries}</td>
                            <td>{normalize_date(contest.start)}</td>
                            <td>{normalize_date(contest.end)}</td>
                            <td><button className="daily-join" onClick={() => join_contest(contest._id)}>Join</button></td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </Col>
        </Row>
        <Rodal visible={joinOpen} onClose={() => setJoinOpen(false)} animation="slideUp" height={600} customStyles={{borderRadius:'15px',width:'90%'}}>
            <JoinContest contestId={joining} />
        </Rodal>
        <Rodal visible={codeOpen} onClose={() => setCodeOpen(false)} animation="slideUp" height={190} width={400} customStyles={{borderRadius:'15px'}}>
            <JoinCode setJoining={setJoining} setJoinOpen={setJoinOpen} setCodeOpen={setCodeOpen}/>
        </Rodal>
        </>
    );
}

export default DailyLobby;