import {useState} from 'react';
import share from 'static/icons/share.svg';
import 'stylesheets/ShareContest.css';
import ReactTooltip from 'react-tooltip';

function ShareContest(props) {
    const [copied,setCopied] = useState(false);
    const STAGE = process.env.NODE_ENV;
    if (STAGE === 'development') {
        var BASE_URL = "http://localhost:3000";
    } else {
        var BASE_URL = "https://flowfantasy.com";
    }
    const copy = () => {
        const str = `${BASE_URL}/daily?open=${props.id}`;
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(true);
    }
    return (
        <div>
            <button className="daily-contest-share"><img className="daily-contest-share-icon" src={share}/>
            <div className="daily-contest-share-modal">
                {props.code ? <h3 className="daily-contest-code">Code: {props.code}</h3> : null}
                <button className="daily-contest-share-copy" data-tip="Copied!" onClick={copy}>Copy Contest Link</button>
                { copied ? <ReactTooltip /> : null}
            </div>
            </button>
            
        </div>
    );
}

export default ShareContest;