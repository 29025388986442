import Draft from "pages/Draft";
import 'stylesheets/nav/DraftNav.css';
import logo from 'static/images/logo.png';
import exit from 'static/icons/exit.svg';

function DraftNav(props) {
    return (
        <div className="draft-nav">
            <div className="draft-brand">
                <img className="draft-nav-logo" src={logo} />
                <h1 className="draft-nav-brand-name">Flow Fantasy</h1>
            </div>
            <div className="exit">
                <a href="/leagues" className="exit-link">Exit <img className="exit-icon" src={exit} /></a>
            </div>
        </div>
    );
}

export default DraftNav;