import React from "react";
import 'stylesheets/LeagueCard.css';

function NLLorPLL(props) {
    if (props.data === 'pll') {
        return <div className="pll">PLL</div>;
    } else {
        return <div className="nll">NLL</div>;
    }
}
function LeagueCard(props) {
    return (
        <a href={`/league/${props.id}`} >
            <div className="league-card" style={{'background-image': `linear-gradient(white, white), radial-gradient(circle at top left, ${props.colors[0]},${props.colors[1]})`}}>
                <div className="league-title">{props.name}</div>
                <NLLorPLL data={props.league} />
            </div>
        </a>
    )

}

export default LeagueCard;
