import { useState, useEffect } from 'react';
import Nav from 'components/nav/Nav';
import DailyNav from 'components/daily/DailyNav.js';
import Cookies from 'js-cookie';
import 'stylesheets/DailyContests.css';
import { Row, Col } from 'react-simple-flex-grid';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ContestView from 'components/daily/ContestView';

function DailyContests(props) {
    const [contests, setContests] = useState([]);
    const [active, setActive] = useState([]);
    const [complete, setComplete] = useState([]);
    const [viewing, setViewing] = useState('');
    const [viewOpen, setViewOpen] = useState(false);
    const [contestSort, setContestSort] = useState('Active');
    const loadContests = async () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        const response = await fetch('/api/daily/contests/me', requestOptions);
        const data = await response.json();
        setContests(data);
        setComplete(data.filter(function (e) {
            return e.status === 'Complete';
        }));
        setActive(data.filter(function (e) {
            return e.status !== 'Complete';
        }));
    }
    useEffect(() => {
        loadContests();
    }, []);
    const normalize_date = (date_str) => {
        var newDate = new Date(date_str);
        var hours = newDate.getHours();
        var minutes = newDate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        var final_date_str = `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()} ${strTime}`;
        return final_date_str;  

    }
    useEffect(() => {
        var tables = document.getElementsByClassName('lobby-table-wrapper');
        console.log(tables)
        for (var i = 0; i < tables.length; i++) {
            tables[i].style.display = 'none';
        } 
        document.getElementById(contestSort).style.display = 'block';
    }, [contestSort]);
    return (
        <>
            <Nav />
            <DailyNav active={'contests'} />
            <div className="contests-header">
                <h1 style={{marginLeft:'45px'}}>My Contests</h1>
                <select className="contest-sort-select" value={contestSort} onChange={(e) => setContestSort(e.target.value)}>
                    <option value="Active">Active</option>
                    <option value="Complete">Complete</option>
                </select>
            </div>
            <div className="daily_main">
                <div className="contests">
                    <Row>
                        <Col span={12} >
                            <div className="lobby-table-wrapper" id="Active">
                            <table className="lobby-table" style={{borderLeft:'none',borderRight:'none',borderTop:'none'}}>
                                <thead >
                                    <tr className="contests-table-header">
                                        <th>Contest</th>
                                        <th>Entries</th>
                                        <th>Status</th>
                                        <th>End Date</th>
                                        <th>My Rank</th>
                                        <th>My Total</th>
                                        <th>Code</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {active.map((contest,index) => (
                                    <tr style={{borderLeft:'2px solid #d6d6d6',borderRight:'2px solid #d6d6d6'}}>
                                        <td>{contest.name}</td>
                                        <td>{contest.entries.length}</td>
                                        <td className={`contest-status ${contest ? contest.status.replace(/ /g, '') : null}`}><b>{contest.status}</b></td>
                                        <td>{normalize_date(contest.end)}</td>
                                        <td>{contest.rank}</td>
                                        <td>{contest.score}</td>
                                        <td>{contest.code}</td>
                                        <td><button className="daily-join" onClick={() => {setViewing(contest._id);setViewOpen(true);}}>View</button></td>
                                    </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                            { active.length === 0 ? <center><div className="no-contests"><h2>You Aren't In Any Active Contests</h2></div></center> : null}
                            </div>
                            <div className="lobby-table-wrapper" id="Complete" style={{display:'none'}}>
                            <table className="lobby-table" style={{borderLeft:'none',borderRight:'none',borderTop:'none'}}>
                                <thead >
                                    <tr className="contests-table-header">
                                        <th>Contest</th>
                                        <th>Entries</th>
                                        <th>Status</th>
                                        <th>My Rank</th>
                                        <th>My Total</th>
                                        <th>Winner</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {complete.map((contest,index) => (
                                    <tr style={{borderLeft:'2px solid #d6d6d6',borderRight:'2px solid #d6d6d6'}}>
                                        <td>{contest.name}</td>
                                        <td>{contest.entries.length}</td>
                                        <td className={`contest-status ${contest ? contest.status.replace(/ /g, '') : null}`}><b>{contest.status}</b></td>
                                        <td>{contest.rank}</td>
                                        <td>{contest.score}</td>
                                        <td>👑 {contest.top.first_name} {contest.top.last_name}</td>
                                        <td><button className="daily-join" onClick={() => {setViewing(contest._id);setViewOpen(true);}}>View</button></td>
                                    </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                            { complete.length === 0 ? <center><div className="no-contests"><h2>You Aren't In Any Complete Contests</h2></div></center> : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Rodal visible={viewOpen} onClose={() => setViewOpen(false)} animation="slideUp" height={600} customStyles={{borderRadius:'15px',width:'90%'}}>
                    <ContestView current={viewing} />
            </Rodal>
        </>
    );
}

export default DailyContests;