import { useState, useEffect } from 'react';
import Nav from 'components/nav/Nav';
import DailyNav from 'components/daily/DailyNav';
import 'stylesheets/CreateLineups.css';
import Cookies, { set } from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {DebounceInput} from 'react-debounce-input';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import DailySchedule from 'components/daily/DailySchedule';
import error from 'static/icons/error.svg';
const EasterEgg = require('react-easter');
const too_many_players = () => toast("✋ Hold up. You have too many players at that position.");
const too_much_cost = () => toast.error("💸 You can't add this player, he costs too much.", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});
const initial = () => toast.error("⚠️ Duke vs Syracuse will be played on Thursday 3/25. Do not select players from these teams if Thursday 3/25 is not within your contest period. Selecting players outside of your contest period will result in 0 points awarded to that position. St. John's does not have a game scheduled during this week's contest period. Selecting a member of this team will result in 0 points awarded to that position. North Carolina does not have a game scheduled during this week's contest period. Selecting a member of this team will result in 0 points awarded to that position.", {
    position: "top-right",
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

function CreateLineup(props) {
    const [editing,setEditing] = useState({});
    const [loadingPlayers, setLoadingPlayers] = useState(false);
    const [players, setPlayers] = useState([]);
    const [sort, setSort] = useState('ATT');
    const [search, setSearch] = useState('');
    const [cost, setCost] = useState(0);
    const [attack, setAttack] = useState([]);
    const [midfield, setMidfield] = useState([]);
    const [defense, setDefense] = useState([]);
    const [fogo, setFogo] = useState([]);
    const [goalie, setGoalie] = useState([]);
    const [page, setPage] = useState(1);
    const konamiCode = [
        'arrowup',
        'arrowup',
        'arrowdown',
        'arrowdown',
        'arrowleft',
        'arrowright',
        'arrowleft',
        'arrowright',
        'b',
        'a',
    ];
    function getQueryParams(qs) {
        qs = qs.split('+').join(' ');

        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    }
    
    useEffect(() => {
        if (editing.attack) {
            setAttack(Object.assign([], editing.attack));
            setMidfield(Object.assign([], editing.midfield));
            var editable_defense = Object.assign([], editing.defense);
            setDefense(editable_defense);
            setFogo(Object.assign([], editing.faceoff));
            setGoalie(Object.assign([], editing.goalie));
            var attack_cost = editing.attack.reduce(function(sum, current) {
                return sum + current.cost;
            }, 0);
            var midfield_cost = editing.midfield.reduce(function(sum, current) {
                return sum + current.cost;
            }, 0);
            var defense_cost = editing.defense.reduce(function(sum, current) {
                return sum + current.cost;
            }, 0);
            var fogo_cost = editing.faceoff.reduce(function(sum, current) {
                return sum + current.cost;
            }, 0);
            var goalie_cost = editing.goalie.reduce(function(sum, current) {
                return sum + current.cost;
            }, 0);
            setCost(attack_cost+midfield_cost+defense_cost+fogo_cost+goalie_cost);
            
        } else {
            setAttack([]);
            setMidfield([]);
            setDefense([]);
            setFogo([]);
            setGoalie([]);
            setCost(0);
        }
    }, [editing]);
    const query = () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        var roster = attack.concat(midfield,defense,fogo,goalie);
		fetch(`/api/daily/players?search=${search}&position=${sort}&limit=25&page=${page}`, requestOptions).then(async response => {
            var data = await response.json();
            var filteredArray  = data.filter(function(array_el){
                return roster.filter(function(anotherOne_el){
                return anotherOne_el.player_id === array_el.player_id;
                }).length == 0
            });
            var filteredArray = [ ...new Set(filteredArray) ];
            if (page === 1) {
                if (filteredArray[0].position === sort || filteredArray[0].player_id === filteredArray[0].team_id) {
                    setPlayers(filteredArray);
                }
            } else {
                if (filteredArray[0].position === sort || filteredArray[0].player_id === filteredArray[0].team_id) {
                    setPlayers([...players, ...filteredArray]);
                }
                
            }
            setLoadingPlayers(false);
		 }).catch((err) => {
            console.log(err)
        })
        
    }
    useEffect(() => {
        var query = getQueryParams(document.location.search);
        if (query['edit']) {
            const requestOptions = {
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
            };
            fetch(`/api/daily/lineups/${query['edit']}`, requestOptions).then(async response => {
                const lineup = await response.json();
                setEditing(lineup);
            });
            
        }
        editFilter()
        initial()
    }, []);
    useEffect(() => {
        setLoadingPlayers(true);
        query();
        
        
    }, [sort,page,search]);
    const position_map = {'ATT':attack,'M':midfield,'D':defense,'GK':goalie,'FO':fogo};
    const position_map_add = {'ATT':setAttack,'M':setMidfield,'D':setDefense,'GK':setGoalie,'FO':setFogo};
    const position_length = {'ATT':3,'M':3,'D':1,'GK':1,'FO':1};
    const add = (player_id,type) => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        fetch(`/api/daily/player/${player_id}?type=${type}`, requestOptions).then(async response => {
            const player = await response.json();
            if (position_map[player['position']].length >= position_length[player['position']]) {
                too_many_players()
            } else if (player.cost + cost > 50000) {
                too_much_cost()
            } else {
                if (position_length[player['position']] > 1) {
                    position_map_add[player['position']]([player].concat(position_map[player['position']]).reverse())
                } else {
                    position_map_add[player['position']]([player])
                }
                setCost(cost+player.cost)
                setPlayers(players.filter(picked_player => picked_player.player_id !== player.player_id || picked_player.team_id !== player.team_id));
            }
            
		 }).catch(() => {
            console.log('Error!')
        })
    }
    const check_complete = () => {
        var complete = true;
            for (var key of Object.keys(position_map)) {
                if (position_map[key].length !== position_length[key]) {
                    var complete = false;
                }
            }
        if (complete === true) {
            document.getElementById('create-lineup').classList.remove('disabled');
        } else {
            document.getElementById('create-lineup').classList.add('disabled');
        }
    }
    const editFilter = () => {
        if (editing.attack) {
            var filteredArray  = players.filter(function(array_el){
                return editing.attack.filter(function(anotherOne_el){
                return anotherOne_el.player_id === array_el.player_id;
                }).length == 0
            });
            setPlayers(filteredArray)
        }
    }
    useEffect(() => {
        editFilter()
    }, [attack]);
    useEffect(() => {
        check_complete()
    }, [attack, midfield, defense, fogo, goalie])
    const remove = (player_id,player_cost,position,type) => {
        if (type !== 'defense') {
            var position_array = position_map[position];
            position_array.splice(position_array.findIndex(function(i){
                return i.player_id === player_id;
            }), 1);
        } else {
            defense.splice(defense.findIndex(function(i){
                return i.player_id === player_id;
            }), 1);
        }
        if (cost-player_cost > 0) {
            setCost(cost-player_cost);
        } else {
            setCost(0);
        }
        query(1)
        check_complete()
        
    }
    const createLineup = () => {
        if (attack.length + midfield.length + fogo.length + defense.length + goalie.length === 9) {
            const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`,'Content-type': 'application/json; charset=UTF-8'},
            method: 'POST',
            body: JSON.stringify({'attack':attack,'midfield':midfield,'faceoff':fogo,'defense':defense,'goalie':goalie})
            };
            fetch('/api/daily/createLineup', requestOptions).then(async response => {
                    const resp_data = await response.json();
                    if (response.ok) {
                        var query = getQueryParams(document.location.search);
                        if (query['contest']) {
                            window.location.href ='/daily?open='+query['contest'];
                        } else {
                            window.location.href = '/daily/lineups';
                        }
                        
                    }
                }
            );
        }
        

    }
    const saveLineup = (lineupId) => {
        if (attack.length + midfield.length + fogo.length + defense.length + goalie.length === 9) {
            const requestOptions = {
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`,'Content-type': 'application/json; charset=UTF-8'},
                method: 'POST',
                body: JSON.stringify({'attack':attack,'midfield':midfield,'faceoff':fogo,'defense':defense,'goalie':goalie})
            };
            fetch(`/api/daily/lineups/${lineupId}`, requestOptions).then(async response => {
                    const resp_data = await response.json();
                    if (response.ok) {
                        window.location.href = '/daily/lineups';
                    }
                }
            );
        }
    }
    return(
        <>
        <Nav />
        <DailyNav />
        <div>
            <ToastContainer />
            <h1 style={{marginLeft:'10px'}}>Create New Lineup</h1>
            <DailySchedule/>
            <Row>
            <Col xs={12} sm={6}>
                <div style={{marginLeft:'10px',marginRight:'10px'}}>
                    <div className="create-lineup-left-header">
                        <DebounceInput
                            minLength={2}
                            debounceTimeout={1000} placeholder="Search" className="player-search" value={search} onChange={(e) => setSearch(e.target.value)} />
                        <select className="position-select" value={sort} onChange={(e) => {setSort(e.target.value);setPage(1);document.getElementById("players_scroll").scrollTop = 0}}>
                            <option value="ATT">ATT</option>
                            <option value="M">M</option>
                            <option value="FO">FO</option>
                            <option value="D">D</option>
                            <option value="GK">GK</option>
                        </select>
                    </div>
                    <div className="players" style={{width:'100%'}} id="players_scroll">
                        <table style={{width:'100%',borderSpacing: '0'}}>
                            <thead className="daily-card">
                                <tr className="lineup-player-row">
                                    <th className="daily-lineup-header">POS</th>
                                    <th className="daily-lineup-header">PLAYER</th>
                                    <th className="daily-lineup-header">TEAM</th>
                                    <th className="daily-lineup-header">Proj.</th>
                                    <th className="daily-lineup-header">SALARY</th>
                                    <th className="daily-lineup-header"></th>
                                </tr>
                            </thead>
                        <tbody className="lineup-body">
                            <EasterEgg keys={konamiCode} timeout={5000}>
                                <tr>
                                    <td>ATT</td>
                                    <td>Sean Goldsmith</td>
                                    <td><span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src="http://web2.ncaa.org/ncaa_style/img/All_Logos/sm/406.gif" /></span></td>
                                    <td></td>
                                    <td>$6000</td>
                                    <td><button className="daily-add-player" onClick={() => add("2130307",'player')}>+</button></td>
                                </tr>
                            </EasterEgg>
                            {players.map((player) => (
                                (player.player_id !== '2130307' ?
                                <tr>
                                    <td >{player.position}</td>
                                    <td>{player.name ? player.name : `${player.firstName} ${player.lastName}`}</td>
                                    <td><span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={player.team_logo} /></span></td>
                                    <td>{player.prediction.toFixed(1)}</td>
                                    <td>${player.cost}</td>
                                    <td><button className="daily-add-player" onClick={() => player.name ? add(player.team_id,player.cost,'defense') : add(player.player_id,'player')}>+</button></td>
                                </tr>
                                : null)
                            ))}
                        </tbody>
                        </table>
                        <center>{search ? null : <button onClick={() => {setLoadingPlayers(true);setPage(page+1)}} className="load-more-player">{loadingPlayers ? 'Loading...' : 'Load More'}</button> }</center>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={6}>
                <div style={{marginLeft:'10px',marginRight:'10px'}}>
                    <div className="create-lineup-right-header">
                        <div className="lineup-right-header">Lineup</div>
                        <div className="player-cost">${cost} / $50000</div>
                    </div>
                    <div className="lineup">
                        <table style={{width:'100%',borderSpacing: '0'}}>
                            <thead className="daily-card">
                                <tr className="lineup-player-row">
                                    <th className="daily-lineup-header">POS</th>
                                    <th className="daily-lineup-header">PLAYER</th>
                                    <th className="daily-lineup-header">TEAM</th>
                                    <th className="daily-lineup-header">Proj.</th>
                                    <th className="daily-lineup-header">SALARY</th>
                                    <th className="daily-lineup-header"></th>
                                </tr>
                            </thead>
                            <tbody className="lineup-body">
                                <tr>
                                    <td>ATT</td>
                                    <td>{ attack[0] ? `${attack[0].firstName} ${attack[0].lastName}` : null}</td>
                                    <td>{ attack[0] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={attack[0].team_logo} /></span> : null}</td>
                                    <td>{ attack[0] ? attack[0].prediction.toFixed(1) : null}</td>
                                    <td>{ attack[0] ? `$${attack[0].cost}` : null}</td>
                                    <td>{ attack[0] ? <button className="daily-remove-player" onClick={() => remove(attack[0].player_id,attack[0].cost,attack[0].position,'player')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>ATT</td>
                                    <td>{ attack[1] ? `${attack[1].firstName} ${attack[1].lastName}` : null}</td>
                                    <td>{ attack[1] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={attack[1].team_logo} /></span> : null}</td>
                                    <td>{ attack[1] ? attack[1].prediction.toFixed(1) : null}</td>
                                    <td>{ attack[1] ? `$${attack[1].cost}` : null}</td>
                                    <td>{ attack[1] ? <button className="daily-remove-player" onClick={() => remove(attack[1].player_id,attack[1].cost,attack[1].position,'player')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>ATT</td>
                                    <td>{ attack[2] ? `${attack[2].firstName} ${attack[2].lastName}` : null}</td>
                                    <td>{ attack[2] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={attack[2].team_logo} /></span> : null}</td>
                                    <td>{ attack[2] ? attack[2].prediction.toFixed(1) : null}</td>
                                    <td>{ attack[2] ? `$${attack[2].cost}` : null}</td>
                                    <td>{ attack[2] ? <button className="daily-remove-player" onClick={() => remove(attack[2].player_id,attack[2].cost,attack[2].position,'player')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>{ midfield[0] ? `${midfield[0].firstName} ${midfield[0].lastName}` : null}</td>
                                    <td>{ midfield[0] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={midfield[0].team_logo} /></span> : null}</td>
                                    <td>{ midfield[0] ? midfield[0].prediction.toFixed(1) : null}</td>
                                    <td>{ midfield[0] ? `$${midfield[0].cost}` : null}</td>
                                    <td>{ midfield[0] ? <button className="daily-remove-player" onClick={() => remove(midfield[0].player_id,midfield[0].cost,midfield[0].position,'player')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>{ midfield[1] ? `${midfield[1].firstName} ${midfield[1].lastName}` : null}</td>
                                    <td>{ midfield[1] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={midfield[1].team_logo} /></span> : null}</td>
                                    <td>{ midfield[1] ? midfield[1].prediction.toFixed(1) : null}</td>
                                    <td>{ midfield[1] ? `$${midfield[1].cost}` : null}</td>
                                    <td>{ midfield[1] ? <button className="daily-remove-player" onClick={() => remove(midfield[1].player_id,midfield[1].cost,midfield[1].position,'player')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>{ midfield[2] ? `${midfield[2].firstName} ${midfield[2].lastName}` : null}</td>
                                    <td>{ midfield[2] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={midfield[2].team_logo} /></span> : null}</td>
                                    <td>{ midfield[2] ? midfield[2].prediction.toFixed(1) : null}</td>
                                    <td>{ midfield[2] ? `$${midfield[2].cost}` : null}</td>
                                    <td>{ midfield[2] ? <button className="daily-remove-player" onClick={() => remove(midfield[2].player_id,midfield[2].cost,midfield[2].position,'player')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>FO</td>
                                    <td>{ fogo[0] ? `${fogo[0].firstName} ${fogo[0].lastName}` : null}</td>
                                    <td>{ fogo[0] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={fogo[0].team_logo} /></span> : null}</td>
                                    <td>{ fogo[0] ? fogo[0].prediction.toFixed(1) : null}</td>
                                    <td>{ fogo[0] ? `$${fogo[0].cost}` : null}</td>
                                    <td>{ fogo[0] ? <button className="daily-remove-player" onClick={() => remove(fogo[0].player_id,fogo[0].cost,fogo[0].position,'player')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>D</td>
                                    <td>{ defense[0] ? defense[0].name : null}</td>
                                    <td>{ defense[0] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={defense[0].team_logo} /></span> : null}</td>
                                    <td>{ defense[0] ? defense[0].prediction.toFixed(1) : null}</td>
                                    <td>{ defense[0] ? `$${defense[0].cost}` : null}</td>
                                    <td>{ defense[0] ? <button className="daily-remove-player" onClick={() => remove(defense[0].player_id,defense[0].cost,defense[0].position,'defense')}>x</button> : null }</td>
                                </tr>
                                <tr>
                                    <td>GK</td>
                                    <td>{ goalie[0] ? `${goalie[0].firstName} ${goalie[0].lastName}` : null}</td>
                                    <td>{ goalie[0] ? <span className="image-cropper reset-lineup-logo-margin"><img className="daily-lineup-team-logo-create" src={goalie[0].team_logo} /></span> : null}</td>
                                    <td>{ goalie[0] ? goalie[0].prediction.toFixed(1) : null}</td>
                                    <td>{ goalie[0] ? `$${goalie[0].cost}` : null}</td>
                                    <td>{ goalie[0] ? <button className="daily-remove-player" onClick={() => remove(goalie[0].player_id,goalie[0].cost,goalie[0].position,'player')}>x</button> : null }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>
            </Row>
            <center>
            {editing.attack ? <button id="create-lineup" className="create-lineup-btn disabled" onClick={() => saveLineup(editing._id)}>Save</button> :
            <button id="create-lineup" className="create-lineup-btn disabled" onClick={createLineup}>Create Lineup</button> }
            </center>
        </div>
        </>
    );
}

export default CreateLineup;