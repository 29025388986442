import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import LoadingSpinner from 'components/LoadingSpinner';
import 'stylesheets/DraftPlayers.css';
import lunr from 'lunr';
import {pickPlayer,autodraftPlayer} from 'api/DraftAPI';

const Sorts = props => {
    const [collapse,setCollapse] = useState(false);
    return (
    <>
        <button className="draft-sort-collapse-btn" onClick={() => setCollapse(!collapse)}>Sort</button>
        <div className={`draft-sorts ${collapse === true ? 'draft-sorts-open' : ''}`}>
            <select className="draft-sort-select" onChange={(e) => props.setStatsFilter(e.target.value)}>
                <option value="2020">2020 Stats</option>
                <option value="2019">2019 Stats</option>
                <option value="2021" disabled={!props.edge ? true : null}>2021 Proj. Stats</option>
                <option value="weekly" disabled={!props.edge ? true : null}>Weekly Proj. Stats</option>
            </select>
            <select className="draft-sort-select" onChange={(e) => props.setTeam(e.target.value)}>
                <option value="all">All Teams</option>
                <option value="ARC">Archers</option>
                <option value="ATL">Atlas</option>
                <option value="CAN">Cannons</option>
                <option value="CHA">Chaos</option>
                <option value="CHR">Chrome</option>
                <option value="RED">Redwoods</option>
                <option value="WHP">Whipsnakes</option>
            </select>
            <select className="draft-sort-select" onChange={(e) => props.setPosition(e.target.value)}>
                <option value="all">All Positions</option>
                <option value="A">A</option>
                <option value="M">M</option>
                <option value="FO">FO</option>
                <option value="D">D</option>
                <option value="G">G</option>
            </select>
        </div>
    </>
    );
}

const PlayerStats = props => {
    if (props.filter === '2020') {
        var stats = props.player.stats['2020'];
    } else if (props.filter === '2019') {
        var stats = props.player.stats['2019'];
    } else if (props.filter === '2021') {
        var stats = props.player.projections['2021'];
    } else if (props.filter === 'weekly') {
        var stats = props.player.projections.week;
    }
    return (
        <>
        {stats ?
        <>
        <td style={{textAlign:'center'}}>{stats.goals}</td>
        <td style={{textAlign:'center'}}>{stats.twoPointGoals}</td>
        <td style={{textAlign:'center'}}>{stats.assists}</td>
        <td style={{textAlign:'center'}}>{stats.groundBalls}</td>
        <td style={{textAlign:'center'}}>{stats.turnovers}</td>
        <td style={{textAlign:'center'}}>{stats.causedTurnovers}</td>
        <td style={{textAlign:'center',minWidth:'80px'}}>{stats.savePct.toFixed(2)}</td>
        <td style={{textAlign:'center',minWidth:'90px'}}>{stats.faceoffPct.toFixed(2)}</td>
        </>
        : 
        <>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        <td style={{textAlign:'center'}}>N/A</td>
        </>
        }
        </>
    );
}

function Players(props) {
    const [statsFilter,setStatsFilter] = useState('2020');
    const [search,setSearch] = useState('');
    const [position,setPosition] = useState('all');
    const [team,setTeam] = useState('all');
    const [allPlayers,setAllPlayers] = useState([]);
    const [filtered,setFiltered] = useState([]);
    useEffect(() => {
        setAllPlayers(props.players.filter(i => props.availablePlayers.includes( i.officialId )));
        setFiltered(allPlayers);
    }, [props.availablePlayers]);
    useEffect(() => {
        var filtered_players = allPlayers;
        //Search
        if (search.trim() !== '') {
            var filtered_players = filtered_players.filter(player => player.firstName.toLowerCase().includes(search.toLowerCase()) || player.lastName.toLowerCase().includes(search.toLowerCase()))
        }
        //Teams
        if (team !== 'all') {
            var filtered_players = filtered_players.filter(player => player.teamId === team)
        }
        //Position
        if (position !== 'all') {
            var filtered_players = filtered_players.filter(player => player.position === position)
        }
        if (filtered_players !== allPlayers) {
            setFiltered(filtered_players)
        } else {
            setFiltered(allPlayers)
        }
    }, [search,position,team,allPlayers]);
    const pick = (player_id) => {
        if (props.picking === true) {
            pickPlayer(props.id,player_id);
            setAllPlayers(allPlayers.filter(player => player.officialId !== player_id))
        } 
    }
    return (
        <>
        <div className="draft-player-table-header">
            <Sorts edge={props.edge} setStatsFilter={setStatsFilter} setPosition={setPosition} setTeam={setTeam} />
            <input className="draft-player-search" type="text" placeholder="Player Search" value={search} onChange={(e) => setSearch(e.target.value)}/>
        </div> 
        <div style={{overflow:'scroll',position:'absolute',width:'100%'}}>
            <table style={{width:'100%',border: '2px solid black',borderCollapse: 'collapse',minWidth:'700px'}}>
                <thead>
                    <tr style={{border: '2px solid black'}}>
                        <th style={{border: '2px solid black'}} colspan="2" scope='colgroup'>PLAYERS</th>
                        <th style={{border: '2px solid black'}} colspan="2" scope='colgroup'>BASICS</th>
                        <th style={{border: '2px solid black'}} colspan="8" scope='colgroup'>STATS <span style={{textTransform:'capitalize'}}>({statsFilter})</span></th>
                        <th style={{border: '2px solid black'}} colspan="2" scope='colgroup'/>
                    </tr>
                    <tr >
                        <th style={{border: '2px solid black'}} scope='col'>POS</th>
                        <th style={{border: '2px solid black'}} scope='col'>Player</th>
                        <th style={{border: '2px solid black'}} scope='col'>Rk</th>
                        <th style={{border: '2px solid black'}} scope='col'>Pts/Game</th>
                        <th style={{border: '2px solid black',minWidth:'20px'}} scope='col'>G</th>
                        <th style={{border: '2px solid black',minWidth:'40px'}} scope='col'>2PT G</th>
                        <th style={{border: '2px solid black',minWidth:'30px'}} scope='col'>A</th>
                        <th style={{border: '2px solid black'}} scope='col'>GB</th>
                        <th style={{border: '2px solid black',minWidth:'30px'}} scope='col'>T</th>
                        <th style={{border: '2px solid black'}} scope='col'>CT</th>
                        <th style={{border: '2px solid black'}} scope='col'>Save %</th>
                        <th style={{border: '2px solid black'}} scope='col'>Faceoff %</th>
                        <th style={{border: '2px solid black'}} scope='col'>Action</th>
                    </tr>
                </thead>
                <tbody style={{width:'100%'}}>
                    {filtered.map((player) => (
                        <tr id={player.officialId}>
                            <td style={{textAlign:'center'}}>{player.position}</td>
                            <td style={{textAlign:'center'}}><span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={player.profileUrl} className="draft-player-img" style={{height:'40px',borderRadius:'20px',marginRight:'3px'}} />{player.firstName} {player.lastName} (<span>{player.teamId}</span>)</span></td>
                            <td style={{textAlign:'center'}}>{player.rank}</td>
                            <td style={{textAlign:'center'}}>{(player.prevSeasonScore/player.prevSeasonGamesPlayed).toFixed(1)}</td>
                            <PlayerStats player={player} filter={statsFilter} />
                            <td style={{textAlign:'center',minWidth:'80px'}}><button className={`draft-pick-up ${props.picking === true ? 'can-pick' : ''}`} onClick={() => pick(player.officialId)}>Pick Up</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </>
    );
}

export default Players;