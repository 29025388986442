import { useState, useEffect } from 'react'; 
import Nav from 'components/nav/Nav';
import DailyNav from 'components/daily/DailyNav.js';
import DailyLobby from 'components/daily/DailyLobby.js';
import DailySchedule from 'components/daily/DailySchedule';
import {PageView, initGA, Event} from 'helpers/Tracking';
import 'stylesheets/pages/Daily.css';

function Daily(props) {
    useEffect(() => {
        initGA('UA-161394853-1');
        PageView();
    }, []);
    return(
        <>
            <Nav />
            <div className="daily_main">
                <DailyNav active="lobby"/>
                <DailySchedule />
                <DailyLobby />
            </div>
            
        </>
        
    );
}

export default Daily;