import { useState } from 'react';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import PreNav from 'components/nav/PreNav';

function ResetPost(props) {
    return (
        <>
        <PreNav />
        <Row>
            <Col xs={12} sm={{span:4,offset:4}}>
                <h1>Success!</h1>
                <p style={{fontSize:'20px'}}>A password reset email has been sent. Make sure you check your spam folder if you don't see the email immediately.</p>
            </Col>
        </Row>
        </>
    );
}

export default ResetPost;