import { useState } from 'react';
import Nav from 'components/nav/Nav';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/forms/AuthForm.css';
import 'stylesheets/CreateContest.css';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import Cookies from 'js-cookie';

function CreateContest(props) {
    const [name, setName] = useState('');
    const [summary, setSummary] = useState('');
    const [timezone, setTimezone] = useState('US/Eastern');
    const [publicContest, setPublicContest] = useState('true');
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [dates, setDates] = useState([new Date(), tomorrow]);
    const handleSubmit = () => {
        if (name.trim() === '') {
            document.getElementById('name').style.borderColor = '#D82334';
        } else if (summary.trim() == '') {
            document.getElementById('summary').style.borderColor = '#D82334';
        } else {
            console.log(dates)
            let data = new FormData();
            data.append('name', name);
            data.append('summary', summary);
            data.append('public', publicContest);
            data.append('start', dates[0].toUTCString());
            data.append('end', dates[1].toUTCString() );
            data.append('timezone', timezone );
            fetch('/api/daily/contests/create', {
                method: 'POST',
                body: data,
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`}
            }).then(async response => {
                const resp_data = await response.json();
                if (!response.ok) {
                    window.location.href = '/daily';
                } else {
                    window.location.href = '/daily?open='+resp_data['id'];
                }
            });
        }
    }
    return (
        <>
            <Nav />
            <Row>
                <Col xs={12} sm={{span: 4,offset:4}}>
                    <center>
                        <h1>Create a Contest</h1>
                        <div className="input-wrapper">
                            <input className="form-input" placeholder="Name" id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <label for="summary">Summary</label>
                        <textarea id="summary" className="summary-area" row={6} colums={50} value={summary} onChange={(e) => setSummary(e.target.value)}/>
                        <h3 className="radio-label">Contest Visability</h3>
                        <select onChange={e => setPublicContest(e.target.value)} className="daily-select">
                            <option value="true">Public</option>
                            <option value="false">Private</option>
                        </select>
                        <h3 className="dates-label">Contest Dates</h3>
                        <DateTimeRangePicker
                            onChange={setDates}
                            value={dates}
                            inline={true}
                        />
                        <input type="submit" className="form-btn login-submit" value="Create Contest" style={{marginTop:'30px'}} onClick={handleSubmit}/>
                    </center> 
                    
                </Col>
            </Row>
        </>
    );
}

export default CreateContest;