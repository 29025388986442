import {useEffect} from 'react';
import PreNav from 'components/nav/PreNav';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import Cookies from 'js-cookie';
import SignUpForm from 'components/forms/SignUpForm';
import {PageView, initGA, Event} from 'helpers/Tracking';

function SignUp(props) {
    useEffect(() => {
        initGA('UA-161394853-1');
        PageView();
    }, []);
    if (Cookies.get('token')) {
        window.location.href = '/leagues'
    }
    return (
        <>
            <PreNav />
            <Row style={{paddingLeft:'100px',paddingRight:'100px'}}>
                <Col xs={12} sm={{span: 4,offset:4}} >
                    <h1>Sign Up</h1>
                    <SignUpForm />
                    <center>
                        <p className="help-wrapper">Already have an account? Sign in <a href="/login" className="help" onClick={() => Event("HELP",'Aleady have account',"SIGN_UP_PAGE")}>here</a>.</p>
                        <p className="help-wrapper">By clicking "Sign Up" you agree to both our <a href="/tos" target="_blank" className="help" onClick={() => Event("HELP",'TOS',"SIGN_UP_PAGE")}>Terms of Sevice</a> and <a href="/privacypolicy" target="_blank" className="help" onClick={() => Event("HELP",'Privacy Policy',"SIGN_UP_PAGE")}>Privacy Policy</a>.</p>
                    </center>
                </Col>
            </Row>
        </>
    );
}

export default SignUp;