import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Nav from 'components/nav/Nav';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/pages/Leagues.css';
import LoadingSpinner from 'components/LoadingSpinner';
import LeagueCard from 'components/LeagueCard';
import LoadingError from 'helpers/LoadingError';
import {PageView, initGA, Event} from 'helpers/Tracking';

function Leagues(props) {
    const [loading,setLoading] = useState(false);
    const [leagues,setLeagues] = useState([]);
    const [error,setError] = useState(false);
    useEffect(() => {
        initGA('UA-161394853-1');
        PageView();
		setLoading(true);
		const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
		  };
		 fetch('/api/leagues', requestOptions).then(async response => {
			 const data = await response.json();
			 // check for error response
			 if (response.status === 400) {
                 // get error message from body or default to response statusText
                 Cookies.remove('token');
				 window.location.href = '/login';
			 } else {
				setLeagues(data);
				setLoading(false);
			 }
 
		 }).catch(() => {
            setError(true);
         })
    }, []);
    return (
        <>
            <Nav />
            <div className="daily_main">
                <div className="header">
                    <div className="page-title" style={{marginLeft:'20px'}}>
                        <h1>Leagues</h1>
                    </div>
                    <div className="create-league" style={{marginRight:'20px'}}>
                        <a className="create-league-link" href="/createLeague" style={{color:'#fff'}}>Create League +</a>
                    </div>
                </div>
                <Row>
                    {loading ? <LoadingSpinner /> :
                    <Row gutter={40} style={{paddingTop:'30px',width:'100%',paddingLeft:'30px'}}>
                        {leagues.map((league) => (
                        <Col xs={12} sm={4} style={{paddingBottom:'20px'}}><center><LeagueCard name={league.name} league={league.league} id={league._id} colors={league.colors} /></center></Col>
                        ))}
                    </Row>
                    }
                </Row>
            </div>
            {error ? <LoadingError error="A Network or Server Error has occured." /> : null}
        </>
    );
}

export default Leagues;