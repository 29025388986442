import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import 'stylesheets/DailySchedule.css';
import {Event} from 'helpers/Tracking';

function DailySchedule(props) {
    const [schedule, setSchedule] = useState([]);
    const loadSchedule = async () => {
        const requestOptions = {
			headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
        };
        const response = await fetch('/api/daily/schedule/week', requestOptions);
        const data = await response.json();
        setSchedule(data);
    }
    useEffect(() => {
        loadSchedule()
    }, []);
    return(
        <div className="daily-schedule">
            <div className="all-games" style={{'background-image': `linear-gradient(#EEE, #EEE), radial-gradient(circle at top left, #FFCB08, #FF5969)`}}>
                All Games
            </div>
            <div className="daily-schedule-scrollable">
                {schedule.map((game) => (
                    <div className="daily-game">
                        <div className="daily-game-home">{game.home_team}</div>
                        <div className="daily-game-away">{game.away_team}</div>
                        <div className="daily-game-time">{game.datetime_str}</div>
                        <a className="daily-game-broadcast" onClick={() => Event('DAILY','View Broadcast', 'DAILY')} href={game.broadcast_url ? game.broadcast_url : `https://www.espn.com/watch/schedule/_/type/upcoming/categoryId/e9b1f7fc-0fe9-304f-8b23-f7ff267f1b72/startDate/${game.espn_broadcast_date}`} target="_blank">Broadcast</a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DailySchedule;