import {useState,useEffect} from 'react';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import {getTeamSettings} from 'api/LeagueAPI';
import LoadingSpinner from 'components/LeagueLoadingSpinner';
import {useQuery} from 'react-query';
import {DebounceInput} from 'react-debounce-input';
import saved_icon from 'static/icons/saved.svg';
import not_saved_icon from 'static/icons/not_saved.svg';
import { Row, Col } from 'react-simple-flex-grid';
import Cookies from 'js-cookie';

const team_logos = ['FlowFantasy-ArchersShield.png','FlowFantasy-AtlasShield.png','FlowFantasy-ChaosShield.png','FlowFantasy-ChromeShield.png','FlowFantasy-GenericShield.png','FlowFantasy-WaterdogsShield.png','FlowFantasy-RedwoodsShield.png','FlowFantasy-WhipsnakesShield.png'];

function TeamSettings({match,location}) {
    const { params: { id } } = match;
    const { isLoading, error, data } = useQuery('team',() => getTeamSettings(id));
    const [teamName,setTeamName] = useState();
    const [teamLogo,setTeamLogo] = useState();
    const [saved,setSaved] = useState(true);
    useEffect(() => {
        if (data) {
            setTeamName(data.name);
            setTeamLogo(data.logo)
        }
    }, [data]);
    useEffect(() => {
        if (data) {
            setSaved(false);
            let update = new FormData();
            update.append('name',teamName);
            update.append('logo',teamLogo);
            const requestOptions = {
                method:'PATCH',
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}`},
                body: update
            }
            fetch(`/api/leagues/${id}/teamSettings`, requestOptions).then(async response => {
                const resp = await response.json();
                setSaved(true);
            });
        }
    }, [teamName,teamLogo]);
    return (
        <>
            <Nav league={true} />
            <LeagueNav id={id} />
            <div className="league-main">
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',flexWrap:'wrap'}}>
                    <h1>Team Settings</h1>
                    <div className="saved-status">
                        <img className="saved-icon" src={saved ? saved_icon : not_saved_icon} />
                        <span className={`saved-text ${saved ? "true" : "not" }`}>Settings Saved</span>
                    </div>
                </div>
                <hr/>
                {isLoading ? <LoadingSpinner /> :
                    <>
                    <div>
                        <h2>Team Name</h2>
                        <DebounceInput debounceTimeout={300} value={teamName} onChange={(e) => setTeamName(e.target.value)} className="team-settings-name"/>
                    </div>
                    <hr/>
                    <div>
                        <h2>Team Logo</h2>
                        <Row>
                        {team_logos.map((logo) => (
                            <Col>
                            <div className={`team-settings-logo-wrapper ${teamLogo === logo ? 'logo-selected' : ''}`} onClick={() => setTeamLogo(logo)}>
                                <img src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${logo}`} className="team-settings-logo"/>
                            </div>
                            </Col>
                        ))}
                        </Row>
                    </div>
                    </>
                }
            </div>
        </>
    );
}

export default TeamSettings;