import {useState, useEffect} from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import Nav from 'components/nav/Nav';
import LeagueNav from 'components/league/LeagueNav';
import { Row, Col } from 'react-simple-flex-grid';
import "react-simple-flex-grid/lib/main.css";
import 'stylesheets/LeagueDashboard.css';
import {useQuery,useMutation} from 'react-query';
import {getLeague,saveLeagueNote,startDraft} from 'api/LeagueAPI';
import {initiateSocket, loadInitialChat, subscribeToChat, sendMessage} from 'api/ChatAPI';
import shield from 'static/icons/shield.svg';
import light from 'static/icons/light.svg';
import settings2 from 'static/icons/settings2.svg';
import players from 'static/icons/players.svg';
import standings from 'static/icons/standings.svg';
import scoreboard from 'static/icons/scoreboard.svg';
import Rodal from 'rodal';
import DateTimePicker from 'react-datetime-picker';
import ScrollToBottom from 'react-scroll-to-bottom';
import ReactTooltip from 'react-tooltip';

const FormatDate = (dateStr) => {
    var weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var date = new Date(dateStr);
    return `${weekday[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`
}

const FormatDate2 = (dateStr) => {
    var weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var date = new Date(dateStr);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return `${weekday[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()} ${strTime}`
}

const FormatMessageDate = (dateStr) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date = new Date(dateStr);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return `${months[date.getMonth()]} ${date.getDate()} ${strTime}`
}

const GetNotificationDate = (dateStr) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date = new Date(dateStr);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    var day = date.getDate();
    return (
        <span>{months[date.getMonth()]} {day}{day === 1 ? 'st' : (day === 2 ? 'nd' : (day === 3 ? 'rd' : 'th'))}<br/>{strTime}</span>
    )
}

function getTimezoneName() {
  return new Date().toString().get(/\((.+)\)/);
}

const TeamCard = props => {
    return (
        <center>
        <div>
            <h3 style={{margin:'0',marginTop:'10px'}}>My Team</h3>
            <hr style={{width:'80%',borderTop:'1px solid #BDC5CD'}}/>
            <div className="team-image-cropper">
                <img className="team-logo" src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.team.logo}`} />
            </div>
            <h3 style={{marginTop:'10px',marginBottom:'0px'}}>{props.team.name}</h3>
            <h4 style={{margin:'0',fontWeight:'400'}}>{props.team.manager.first_name} {props.team.manager.last_name}</h4>
            <button className="league-team-roster-btn" onClick={() => window.location.href=`/league/${props.id}/team`}>Roster</button>
        </div>
        </center>
    );
}

const LeagueCard = props => {
    const [startDraftModal,setStartDraftModal] = useState(false);
    
    return (
        <>
        <div className="league-card-header">
            <div>
                <h1 style={{marginTop:'3px',marginLeft:'10px'}}>{props.league.name}</h1>
                <div className="league-card-btns" style={{paddingLeft:'10px',marginTop:'-10px'}}>
                    <button className="league-card-btn" style={{marginRight:'5px'}} onClick={() => window.location.href=`/league/${props.league._id}/managers`}>Managers</button>
                    <button className="league-card-btn" onClick={() => window.location.href=`/league/${props.league._id}/teams`}>Teams</button>
                </div>
            </div>
            <div className="league-card-details">
                <p>Creator: {props.league.creator.first_name[0]}. {props.league.creator.last_name}</p>
                <p>Teams: {props.league.teams.length}</p>
                <p>Created: {FormatDate(props.league.created)}</p>
            </div>
        </div>
        <center>
        {props.league.draftHeld !== true ? 
        <div className="league-card-footer">
            <div>
                <button className="league-card-message-btn managers-btn" onClick={() => window.location.href=`/league/${props.league._id}/managers`}>Invite Managers</button>
                {props.league.admin ?
                    (props.league.teams.length > 1 ?
                        <button className="league-card-message-btn schedule-btn" onClick={() => setStartDraftModal(true)}>Start Draft</button>
                    : <><button className="league-card-message-btn schedule-btn" data-tip="You need more than 1 team to start a draft">Start Draft</button><ReactTooltip /></>)
                : null }
            </div>
        </div>
        : null }
        </center>
        <Rodal visible={startDraftModal} onClose={() => setStartDraftModal(false)}>
            <div>
                <h1 style={{marginTop:'0px',marginBottom:'10px'}}>Hold Up ✋</h1>
                <h3 style={{fontWeight:'400',marginTop:'0px'}}>Are you sure you want to start your draft. Once it has started you can't add anyone to your league unless you reset the draft.</h3>
                <center><button className="start-draft-btn" onClick={() => startDraft(props.league._id)}>Yes, I'm Sure</button></center>
            </div>
        </Rodal>
        </>
    );
}

const QuickLinksCard = props => {
    return (
    <center>
    <div>
        <h3 style={{margin:'0',marginTop:'10px'}}>Quick Links</h3>
        <hr style={{width:'80%',borderTop:'1px solid #BDC5CD'}}/>
        <button className="league-card-link" onClick={() => window.open('https://www.notion.so/Rules-Scoring-32e25424a0964605af59768c1fdd337b','_blank')}><img src={shield} />Rules</button>
        <hr style={{width:'80%',borderTop:'1px solid #BDC5CD'}}/>
        <button className="league-card-link" onClick={() => window.open('https://www.notion.so/PLL-1d99f3379bfc448c9912fa8ca3593c6f','_blank')}><img src={light} />FAQs</button>
        <hr style={{width:'80%',borderTop:'1px solid #BDC5CD'}}/>
        <button className="league-card-link" onClick={() => window.location.href = `/league/${props.id}/teamSettings`}><img src={settings2} />Settings</button>
    </div>
    </center>
    );
}

const LeagueNotesCard = props => {

    return (
        <div style={{overflow:'scroll'}}>
        <div className="league-note-card-header">
            <h2 style={{marginTop:'8px',marginLeft:'10px',marginBottom:'0px'}}>League Notes</h2>
            {props.league.admin ? <a style={{marginRight:'10px',marginTop:'8px',color:'#004D9A'}} onClick={() => props.open(true)}>Edit</a> : null}
        </div>
        <hr style={{width:'95%',borderTop:'0.5px solid #BDC5CD'}}/>
        <div className="league-note-wrapper"><p className="league-note" id="league-note">{props.league.league_note !== '' || props.league.league_note !== null ? props.league.league_note : 'Welcome to your Flow Fantasy Lacrosse league. Your League Manager will have the opportunity to post a League Manager Note to the entire league and that will appear in this area.'}</p></div>
        </div>
    );
}

const LeagueLinksCard = props => {
    return (
        <center>
        <div style={{marginTop:'10px'}}>
            <button className="league-card-link" onClick={() => window.location.href = `/league/${props.id}/waiver`}><img src={players} />Players</button>
            <hr style={{width:'80%',borderTop:'0.5px solid #BDC5CD'}}/>
            {/* <button className="league-card-link"><img src={standings} />Standings</button>
            <hr style={{width:'80%',borderTop:'0.5px solid #BDC5CD'}}/>
            <button className="league-card-link"><img src={scoreboard} />Scoreboard</button>
            <hr style={{width:'80%',borderTop:'0.5px solid #BDC5CD'}}/> */}
            <button className="league-card-link" onClick={() => window.location.href = `/league/${props.id}/team`}>Team</button>
        </div>
        </center>
    );
}

const Message = props => {
    return (
        <div style={{marginBottom:'20px',marginLeft:'20px'}}>
            <div style={{display:'flex'}}>
                <img style={{height:'40px',borderRadius:'20px'}} src={`https://api.flowfantasy.com/api/rango/files/teamLogos/${props.data.team.logo}`} />
                <div style={{marginLeft:'10px'}}>
                    <div style={{alignSelf:'flex-start'}}><span style={{color:'#151617',fontWeight:'600',fontSize:'14px'}}>{props.data.name}</span> <span style={{fontSize:'12px',color:'#151617'}}>({props.data.team.name})</span> <span style={{color:'#a5a6a7',fontSize:'11px'}}>• {FormatMessageDate(props.data.timestamp)}</span></div>
                    <div style={{alignSelf:'flex-end',justifySelf:'flex-start',color:'#6c6d6f'}}>{props.data.message}</div>
                </div>
            </div>

        </div>
    );
}

const LeagueChatCard = props => {
    const [message,setMessage] = useState('');
    const [chat,setChat] = useState([]);
    const [teams,setTeams] = useState({})
    useEffect(() => {
        initiateSocket(props.id);
        loadInitialChat((err, data) => {
            if(err) return;
            var final_teams = {};
            data.teams.map((team) => (
                final_teams[team.user] = {'name':team.name,'logo':team.logo}
            ));
            data.messages.map((message) => (
                message['team'] = final_teams[message['user']]
            ));
            setChat(data.messages);
        });
        subscribeToChat((err, data) => {
            if(err) return;
            data.message.team = {'name':data.team.name,'logo':data.team.logo}
            setChat(oldChats =>[...oldChats, data.message])
        });
    }, []);
    return (
        <div>
            <div className="league-chat-card-header">
                <h2 style={{marginTop:'8px',marginLeft:'10px',marginBottom:'0px'}}>League Chat</h2>
                <a style={{marginRight:'10px',marginTop:'8px',color:'#004D9A'}} href={`/league/${props.id}/chat`}>View Full Chat</a>
            </div>
            <hr style={{width:'95%',borderTop:'1px solid #BDC5CD'}}/>
            <ScrollToBottom className="league-chat">
                {chat ? chat.map((message) => (<Message data={message}/>)) : null}
            </ScrollToBottom>
            <div className="league-chat-form" style={{width:'100%'}}>
                <input type="text" placeholder="Talk Smalk" value={message} onChange={(e) => setMessage(e.target.value)} style={{border:'1px solid rgb(165, 166, 167)',height:'35px',marginBottom:'10px',marginLeft:'10px',paddingLeft:'8px',marginRight:'10px',width:'90%'}} />
                <button className={`league-chat-send ${message.length > 0 ? 'active' : ''}`} onClick={() => {sendMessage(props.id,message);setMessage('')}}>Send</button>
            </div>
        </div>
    );
}

const LeagueActivityCard = props => {
    return (
        <div>
            <div className="league-activity-card-header">
                <h2 style={{marginTop:'8px',marginLeft:'10px',marginBottom:'0px'}}>League Activity</h2>
                <a style={{marginRight:'10px',marginTop:'8px',color:'#004D9A'}} href={`/league/${props.id}/notifications`}>View Full Activity</a>
            </div>
            <hr style={{width:'95%',borderTop:'1px solid #BDC5CD'}}/>
            <center>
            <table style={{width:'90%',borderCollapse:'collapse',borderStyle:'hidden',marginBottom:'20px'}}>
                <thead className="league-activity-header">
                    <tr>
                        <th style={{fontSize:'13px'}}>DATE</th>
                        <th style={{fontSize:'13px'}}>TYPE</th>
                        <th style={{fontSize:'13px'}}>DETAILS</th>
                    </tr>
                </thead>
                <tbody className="league-activity-body">
                    {props.activity.map((notification) => (
                        <tr>
                            <th>{GetNotificationDate(notification.created_at)}</th>
                            <th>{notification.type}</th>
                            <th>{notification.details}</th>
                        </tr>
                    ))}
                </tbody>
            </table>
            </center>
        </div>
    );
}

const LeagueNote = props => {
    const [noteValue,setNoteValue] = useState(props.league.league_note);
    useEffect(() => {
        if (noteValue !== props.league.league_note) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [noteValue]);
    const [changed,setChanged] = useState(false);
    const mutation = useMutation('leagueData',(note) => {
        var resp = saveLeagueNote(props.league._id,note)
        document.getElementById('league-note').innerHTML = note.note;
        props.closeNoteModal(false);
    });
    return (
        <div>
            <h2 style={{marginTop:'-8px'}}>League Note</h2>
            <textarea value={noteValue} onChange={(e) => setNoteValue(e.target.value)} style={{width:'100%',minHeight:'100px',resize:'none',borderColor:'#000',borderWidth:'1px',borderRadius:'10px'}}/>
            <button className={`league-note-save-btn ${changed ? 'ready' : ''}`} onClick={() => {if (changed === true) {mutation.mutate({note:noteValue})}}}>Save</button>
        </div>
    );
}

function LeagueDashboard(props) {
    const [draftOpen,setDraftOpen] = useState(false);
    const [leagueNoteOpen,setLeagueNoteOpen] = useState(false);
    const { isLoading, error, data } = useQuery('leagueData', () => getLeague(props.id));

    if (isLoading) return <LoadingSpinner/>;

    return (

        <>
        <Nav league={true}/>
        <LeagueNav current="league" id={props.id} />
        <div className="league-main">
            <Row >
                <Col xs={12} sm={2} className="league-team-card" style={{marginRight:'10px',marginLeft:'10px'}}><TeamCard id={props.id} team={data.team}/></Col>
                <Col xs={12} sm={6} className="league-dashboard-card" style={{marginRight:'10px',marginLeft:'10px'}}><LeagueCard league={data.league} setDraftOpen={setDraftOpen}/></Col>
            </Row>
            <Row>
                <Col xs={12} sm={2} className="quick-links-card" style={{marginRight:'10px',marginLeft:'10px'}}><QuickLinksCard id={props.id}/></Col>
                <Col xs={12} sm={6} className="league-notes-card" style={{marginRight:'10px',marginLeft:'10px',marginTop:'30px'}}><LeagueNotesCard league={data.league} open={setLeagueNoteOpen} /></Col>
            </Row>
            <Row>
                <Col xs={12} sm={2} className="league-links-card" style={{marginRight:'10px',marginLeft:'10px',marginTop:'30px',height:'100px'}}><LeagueLinksCard id={props.id}/></Col>
                <Col xs={12} sm={6} className="league-chat-card" style={{marginRight:'10px',marginLeft:'10px',marginTop:'30px'}}><LeagueChatCard id={props.id}/></Col>
            </Row>
            <Row>
                <Col xs={12} sm={2} style={{marginRight:'10px',marginLeft:'10px',width:'200px'}} />
                <Col xs={12} sm={6} className="league-activity-card" style={{marginRight:'10px',marginLeft:'10px',marginTop:'30px'}}><LeagueActivityCard id={props.id} activity={data.activity}/></Col>
            </Row>
        </div>
        {/* <Rodal visible={draftOpen} onClose={() => setDraftOpen(false)}>
            <DraftScheduler id={props.id} league={data} closeDraftModal={setDraftOpen}/>
        </Rodal> */}
        <Rodal visible={leagueNoteOpen} onClose={() => setLeagueNoteOpen(false)} height={300} customStyles={{borderRadius:'15px',width:'80%'}}>
            <LeagueNote league={data.league} closeNoteModal={setLeagueNoteOpen}/>
        </Rodal>
        </>
    )
}

export default LeagueDashboard;